import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FontSize } from '../../../enums';
import { ConfigService, ProfilBaseService } from '../../../services';
import { UtilsService } from '../../../services/utils/utils.service';
import { ContactezConseillerDialogComponent } from '../contactez-conseiller/contactez-conseiller.dialog';

@Component({
    selector: 'app-contactez-nous',
    templateUrl: './contactez-nous.dialog.html',
    styleUrls: ['./contactez-nous.dialog.scss'],
})
export class ContactezNousDialogComponent implements OnInit {
    FontSize = FontSize;
    numeroContact!: string;
    lienNumeroContact!: string;
    origineDevis!: string;

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ContactezNousDialogComponent>,
        private configService: ConfigService,
        private utilsService: UtilsService,
        private profilService: ProfilBaseService,
    ) {}

    ngOnInit(): void {
        this.origineDevis = this.profilService.getOrigineDevis();
        this.numeroContact =
            this.origineDevis === 'Comparateur'
                ? this.configService.numeroContactMass
                : this.configService.numeroContact;

        this.lienNumeroContact = this.utilsService.formatTel(this.numeroContact);
    }

    openContactezConseillerDialog() {
        this.dialogRef.afterClosed().subscribe((value) => {
            this.dialog.open(ContactezConseillerDialogComponent);
        });
        this.dialogRef.close();
    }
}
