import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
    message: string = 'Une erreur serveur est survenue. Merci de réessayer.';
    status: SnackStatus = SnackStatus.INFO;

    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: any) {
        if (data.status != null) {
            this.status = data.status;
        }
        if (data.message != null) {
            this.message = data.message;
        }
    }
}

export enum SnackStatus {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error',
}
