import { Injectable } from '@angular/core';
import { GtmMappageService as LibGtmMappageService } from 'common-lib';
import { Profil } from '../../models/profil';
import { NiveauBesoin } from '../../models/niveau-besoin';

@Injectable({
    providedIn: 'root',
})
export class GtmMappageService extends LibGtmMappageService {
    pushClickNeedsAdjustement(profil: Profil, newNiveauBesoin: NiveauBesoin) {
        if (profil.profilSoel?.niveauBesoin) {
            let modifiedNeed = [];
            if (newNiveauBesoin.soinsCourants !== profil.profilSoel?.niveauBesoin.soinsCourants) {
                modifiedNeed.push('Soins courants');
            }

            if (newNiveauBesoin.hospitalisation !== profil.profilSoel?.niveauBesoin.hospitalisation) {
                modifiedNeed.push('Hospitalisation');
            }

            if (newNiveauBesoin.dentaire !== profil.profilSoel?.niveauBesoin.dentaire) {
                modifiedNeed.push('Dentaire');
            }

            if (newNiveauBesoin.optique !== profil.profilSoel?.niveauBesoin.optique) {
                modifiedNeed.push('Optique');
            }

            this.gtmService.pushTag({
                event: 'click_needs_adjustment',
                client_type: this.getClientTypeByProfil(profil),
                modified_need: modifiedNeed.toString(),
            });
        }
    }
}
