import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmData, ProfilBase } from '../../../models';
import { ConfigService, ProfilBaseService } from '../../../services';
import { ConfirmDialogComponent } from '../../dialogs';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
    @Input() form!: UntypedFormGroup;
    @Input() fieldName!: string;
    @Input() labelName!: string;
    @Input() hintMessage?: string;
    @Input() arrayIndex?: number;
    @ViewChild('fileInput') fileInput!: ElementRef;
    private profil!: ProfilBase;
    public fieldNameFileName!: string;

    constructor(
        private dialog: MatDialog,
        private configService: ConfigService,
        private profilService: ProfilBaseService,
    ) {
        this.profilService.profil.subscribe((value) => {
            this.profil = value;
        });
    }

    ngOnInit() {
        this.fieldNameFileName = this.fieldName + 'FileName';
    }

    onFileSelected(event: Event) {
        const files = (<HTMLInputElement>event.target)?.files;
        if (files && files[0]) {
            const file = files[0];
            this.fileNameInputFormControl?.setValue(file.name);
            this.fileInputFormControl?.setErrors(null);
        } else {
            this.fileNameInputFormControl?.setValue('');
        }
    }

    deleteFile(event: Event) {
        const confirmData = <ConfirmData>{
            icon: 'infos-client',
            title: 'Suppression du fichier',
            description: 'Vous souhaitez supprimer ce fichier ?',
            confirmText: 'Confirmer',
            onConfirm: (confirmDialog: MatDialogRef<ConfirmDialogComponent>) => {
                this.fileInputFormControl?.setValue('');
                this.fileNameInputFormControl?.setValue('');
                confirmDialog.close();
            },
        };
        this.dialog.open(ConfirmDialogComponent, { data: confirmData });

        event.preventDefault();
        event.stopPropagation();
    }

    showFile(input: HTMLInputElement) {
        const files = input.files;
        if (files && files[0]) {
            window.open(URL.createObjectURL(files[0]));
        } else {
            window.open(this.configService.getProfilFileApiUrl(this.profil, this.fileNameInputFormControl?.value));
        }
    }

    get fileInputFormControl(): UntypedFormControl {
        if (undefined !== this.arrayIndex) {
            return <UntypedFormControl>(<UntypedFormArray>this.form.get(this.fieldName)).controls[this.arrayIndex];
        } else {
            return <UntypedFormControl>this.form.get(this.fieldName);
        }
    }

    get fileNameInputFormControl(): UntypedFormControl {
        if (undefined !== this.arrayIndex) {
            return <UntypedFormControl>(
                (<UntypedFormArray>this.form.get(this.fieldNameFileName)).controls[this.arrayIndex]
            );
        } else {
            return <UntypedFormControl>this.form.get(this.fieldNameFileName);
        }
    }

    get formDataFieldName(): string {
        if (undefined !== this.arrayIndex) {
            return `${this.fieldName}[${this.arrayIndex}]`;
        } else {
            return this.fieldName;
        }
    }
}
