export enum IndicatifTelephonique {
    FRANCE_METROPOLITAINE = '33',
    LA_REUNION_MAYOTTE = '262',
    GUADELOUPE = '590',
    GUYANE = '594',
    MARTINIQUE = '596',
}

export interface Indicatif {
    key: string;
    value: string;
}

interface RacineInterface extends Record<IndicatifTelephonique, string[]> {}

/**
 * Liste des racines autorisées par indicatif pour la France métropolitaine et
 * les départements et régions d'outre mer (DROM)
 * Ces racines correspondent aux dernières règles mises en place par l'Arcep
 * dans sa décision du 8 avril 2021 concernant le plan de numérotation
 * @see https://www.arcep.fr/la-regulation/grands-dossiers-thematiques-transverses/la-numerotation.html
 * @see https://www.arcep.fr/uploads/tx_gsavis/21-0532.pdf
 */
export const Racines = <RacineInterface>{};

Racines[IndicatifTelephonique.FRANCE_METROPOLITAINE] = [
    '0601',
    '0602',
    '0603',
    '0604',
    '0605',
    '0606',
    '0607',
    '0608',
    '0609',
    '061',
    '062',
    '0630',
    '0631',
    '0632',
    '0633',
    '0634',
    '0635',
    '0636',
    '0637',
    '0638',
    '0650',
    '0651',
    '0652',
    '0656',
    '0657',
    '0658',
    '0659',
    '064',
    '066',
    '067',
    '068',
    '0695',
    '0698',
    '0699',
    '073',
    '0740',
    '0741',
    '0742',
    '0743',
    '0744',
    '0745',
    '0748',
    '0749',
    '075',
    '076',
    '077',
    '078',
];
Racines[IndicatifTelephonique.LA_REUNION_MAYOTTE] = ['0639', '0692', '0693'];
Racines[IndicatifTelephonique.GUADELOUPE] = ['0690', '0691'];
Racines[IndicatifTelephonique.GUYANE] = ['0694'];
Racines[IndicatifTelephonique.MARTINIQUE] = ['0696', '0697'];
