export enum Couleur {
    ROUGE = 'rouge',
    ROSE = 'rose',
    TURQUOISE = 'turquoise',
    GRIS1 = 'gris1',
    GRIS2 = 'gris2',
    GRIS3 = 'gris3',
    GRIS4 = 'gris4',
    BLANC = 'blanc',
}
