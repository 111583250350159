import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { RepriseResponse } from '../models';

@Injectable({
    providedIn: 'root',
})
export class RepriseRepository extends Repository {
    smsMessage(progress: string): Observable<RepriseResponse> {
        return this.http.post<AuthenticationCodeResponse>('/api/authentication_code', { progress: progress }).pipe(
            switchMap((authenticationCodeResponse: AuthenticationCodeResponse) => {
                return of(authenticationCodeResponse.smsMessage);
            }),
        );
    }

    resendSms(progress: string) {
        return this.http
            .post<ResendAuthenticationCodeResponse>('/api/authentication_code/resend', { progress: progress })
            .pipe(
                switchMap((resendAuthenticationCodeResponse: ResendAuthenticationCodeResponse) => {
                    return of(resendAuthenticationCodeResponse.resend);
                }),
            );
    }
}

interface AuthenticationCodeResponse {
    message: string;
    smsMessage: RepriseResponse;
}

interface ResendAuthenticationCodeResponse {
    message: string;
    resend: boolean;
}
