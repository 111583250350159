<a
  *ngIf="href"
  [href]="href"
  [attr.target]="target"
  class="button-link rounded-pill text-uppercase text-center elementPrincipal"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="routerLink"
  [routerLink]="routerLink"
  class="button-link rounded-pill text-uppercase text-center elementPrincipal"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<button
  *ngIf="!href && !routerLink"
  class="rounded-pill text-uppercase text-center elementPrincipal"
  [type]="submit ? 'submit' : 'button'"
  [disabled]="disabled || loading"
  [ngStyle]="{ 'font-size': applyFontSize(fontSize) }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>


<ng-template #content>
  <app-bouton-loader *ngIf="loading"></app-bouton-loader>
  <ng-content *ngIf="!loading"></ng-content>
</ng-template>
