import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../../config/app.config';
import { Configuration, ProfilBase } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private configuration: Configuration | any;

    constructor(
        @Inject(APP_CONFIG) configuration: Configuration,
        private http: HttpClient,
    ) {
        this.configuration = configuration;
    }

    get apiUrl(): string {
        if (typeof this.configuration.apiUrl === 'undefined') {
            throw new Error(
                'apiUrl is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.apiUrl;
    }

    get apiZip(): string {
        return this.configuration.zipUrl;
    }

    get apiDomain(): string {
        const url = new URL(this.apiUrl);
        return url.hostname;
    }

    get cookieCbid(): string {
        if (typeof this.configuration.cookieCbid === 'undefined') {
            throw new Error(
                'cookieCbid is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.cookieCbid;
    }
    get gtmID(): string {
        if (typeof this.configuration.gtmID === 'undefined') {
            throw new Error(
                'gtmID is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.gtmID;
    }

    get smiUrlProduit(): string {
        return this.configuration.smiUrlAccueil + '/produit/';
    }

    get smiUrlAccueil(): string {
        if (typeof this.configuration.smiUrlAccueil === 'undefined') {
            throw new Error(
                'smiUrlAccueil is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.smiUrlAccueil;
    }

    get numeroContact(): string {
        if (typeof this.configuration.numeroContact === 'undefined') {
            throw new Error(
                'numeroContact is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.numeroContact;
    }

    get numeroContactMass(): string {
        if (typeof this.configuration.numeroContactMass === 'undefined') {
            throw new Error(
                'numeroContactMass is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.numeroContactMass;
    }

    get recaptchaPublicKey(): string {
        if (typeof this.configuration.recaptchaPublicKey === 'undefined') {
            throw new Error(
                'recaptchaPublicKey is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.recaptchaPublicKey;
    }

    get verificationSignatureInterval(): number {
        if (typeof this.configuration.verificationSignatureInterval === 'undefined') {
            throw new Error(
                'verificationSignatureInterval is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.verificationSignatureInterval;
    }

    getProfilFileApiUrl(profil: ProfilBase, fileName: string): string {
        return `${this.apiUrl}/api/profil/${profil.id}/file/${fileName}?token=${localStorage.getItem('authToken')}`;
    }

    get urlEspaceAdherent(): string {
        if (typeof this.configuration.urlEspaceAdherent === 'undefined') {
            throw new Error(
                'urlEspaceAdherent is missing from the configuration object. Please update your /config/configuration.json file',
            );
        }

        return this.configuration.urlEspaceAdherent;
    }
}
