<ul>
  <li>
    <a href="https://www.mutuelle-smi.com" target="_blank">
<!--      <app-icon nomIcon="offres" [stroke]="true" size="2.3"></app-icon>-->
      <span>Nos offres pour les particuliers</span>
    </a>
  </li>
  <li>
    <a href="https://www.mutuelle-smi.com/protection-des-donnees/" target="_blank">
<!--      <app-icon nomIcon="contact" [stroke]="true" size="2.3"></app-icon>-->
      <span>Politique de protection des données personnelles</span>
    </a>
  </li>
  <li>
    <a href="https://www.mutuelle-smi.com/mentions-legales/" target="_blank">
<!--      <app-icon nomIcon="mentions" [stroke]="true" size="2.3"></app-icon>-->
      <span>Mentions légales</span>
    </a>
  </li>
  <li>
    <a href="https://www.mutuelle-smi.com/" target="_blank">
<!--      <app-icon nomIcon="retour" [stroke]="true" size="2.3"></app-icon>-->
      <span>Retour vers le site SMI</span>
    </a>
  </li>
</ul>
