<footer>
  <div class="conteneur-elastique d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center py-5 px-4">
    <div class="mb-3 mb-md-0 ms-md-5">
      <app-picto nomPicto="logo-smi-blanc"></app-picto>
    </div>
    <div class="d-block text-center text-md-end me-md-5">
      <a class="footer-link m-3" href="https://www.mutuelle-smi.com/mentions-legales/" >Mentions légales</a>
      <a class="footer-link m-3" href="https://www.mutuelle-smi.com/protection-des-donnees/" >Protection des données</a>
    </div>
  </div>
</footer>

