import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { ProfilBase, RechercheProfilClient } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ProfilClientRepository extends Repository {
    search(rechercheClient: RechercheProfilClient): Observable<ProfilBase> {
        return this.http.post<ProfilClientResponse>('/api/profil_client', rechercheClient).pipe(
            switchMap((ProfilClientResponse) => {
                return of(ProfilClientResponse.profil);
            }),
        );
    }
}

interface ProfilClientResponse {
    message: string;
    profil: ProfilBase;
}
