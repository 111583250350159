import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProfilBaseService } from '../../services';
import { ConfirmDialogComponent, SnackbarComponent, SnackStatus } from '../../components';
import { ConfirmData } from '../../models';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private matSnackBar: MatSnackBar,
        private profilService: ProfilBaseService,
        private router: Router,
        private dialog: MatDialog,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (401 === err.status) {
                    const authenticate = err.headers.get('www-authenticate') ?? '';
                    if ('2FA' === authenticate) {
                        this.router.navigate(['/reprise'], { queryParamsHandling: 'preserve' });
                    } else if (0 === authenticate.indexOf('Bearer')) {
                        const newProgress = authenticate.replace('Bearer progress=', '');
                        const confirmData = <ConfirmData>{
                            icon: 'attention',
                            title: 'Attention',
                            description:
                                "La date d'effet que vous avez initialement choisie pour le démarrage de votre contrat est désormais dépassée. Nous vous invitons à la modifier dans le formulaire suivant.",
                            confirmText: 'Modifier',
                            hideCancel: true,
                            hideCloseIcon: true,
                            onConfirm: (confirmDialog: MatDialogRef<ConfirmDialogComponent>) => {
                                confirmDialog.close();
                            },
                        };
                        this.dialog
                            .open(ConfirmDialogComponent, { disableClose: true, data: confirmData })
                            .afterClosed()
                            .toPromise()
                            .then(() => {
                                this.router.navigate(['/'], { queryParams: { progress: newProgress } }).then(() => {
                                    window.location.reload();
                                });
                            });
                    } else {
                        this.profilService.clearProfil();
                        localStorage.setItem('expiredSession', 'true');
                        window.location.reload();
                    }
                }
                return this.handleServerError(err);
            }),
        );
    }

    private handleServerError(err: HttpErrorResponse): Observable<any> {
        if (err.status !== 422 && !ErrorHandlerInterceptor.byPassSnackBar(err)) {
            this.matSnackBar.openFromComponent(SnackbarComponent, {
                data: {
                    status: SnackStatus.ERROR,
                    message: err.error?.message,
                },
                duration: 3000,
            });
        }

        return throwError(err);
    }

    private static byPassSnackBar(err: HttpErrorResponse): boolean {
        return (
            (400 === err.status && 'La requête est incorrecte.' === err.error?.message) ||
            (401 === err.status && '2FA' === err.headers.get('www-authenticate'))
        );
    }
}
