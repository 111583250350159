import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNieme',
})
export class FormatNiemePipe implements PipeTransform {
    transform(value: number): string {
        let sup;

        switch (value) {
            case 1:
                sup = 'er';
                break;
            case 2:
                sup = 'nd';
                break;
            default:
                sup = 'ème';
                break;
        }

        return value + '<sup>' + sup + '</sup>';
    }
}
