import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../services';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationRepository extends Repository {
    private configService: ConfigService;

    constructor(client: HttpClient, configService: ConfigService) {
        super(client);
        this.configService = configService;
    }

    getAccessToken(refreshToken: string | undefined): Observable<TokenResponse> {
        if (refreshToken) {
            return this.http.post<TokenResponse>(`${this.configService.apiUrl}/api/refresh_token`, {
                refresh_token: refreshToken,
            });
        } else {
            let data = {};
            const cleDeRepriseCommerciale = localStorage.getItem('reprise');
            const cleDeReprise = localStorage.getItem('progress');

            if (cleDeRepriseCommerciale && cleDeReprise) {
                throw new Error('Les deux paramètres reprise et progress ne doivent pas coéxister');
            } else if (cleDeReprise) {
                const code = localStorage.getItem('codeSms');
                if (code) {
                    data = { progress: cleDeReprise, code: code };
                } else {
                    data = { progress: cleDeReprise };
                }
                localStorage.removeItem('progress');
                localStorage.removeItem('codeSms');
            } else if (cleDeRepriseCommerciale) {
                data = { reprise: cleDeRepriseCommerciale };
                localStorage.removeItem('reprise');
            }
            return this.http.post<TokenResponse>(`${this.configService.apiUrl}/api/token`, data);
        }
    }
}

export interface TokenResponse {
    message: string;
    data: {
        token: string;
        expireAt: Date;
        refreshToken: string;
        refreshTokenExpireAt: Date;
    };
}
