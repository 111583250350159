import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CssService } from '../../../services/css/css.service';
import { Couleur } from '../../../enums';
@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
    @Input() color: Couleur = Couleur.TURQUOISE;
    @Input() size: string = '3';
    @Input() nomIcon: string = '';
    @Input() stroke: boolean = false;

    private cssService: CssService;
    private ref: ElementRef;

    constructor(cssService: CssService, ref: ElementRef) {
        this.cssService = cssService;
        this.ref = ref;
    }

    ngOnInit(): void {
        this.applyCssColor(this.color);
    }

    private applyCssColor(color: Couleur): void {
        if (!this.stroke) {
            this.ref.nativeElement.querySelector('svg').style.fill = this.cssService.formatCssVar(color);
        } else {
            this.ref.nativeElement.querySelector('svg').style.fill = 'transparent';
            this.ref.nativeElement.querySelector('svg').style.stroke = this.cssService.formatCssVar(color);
        }
    }
}
