<mat-form-field appearance="outline" class="w-100 cursor-pointer" (click)="fileInput.click()" [formGroup]="form" >
  <mat-label>{{ labelName }}</mat-label>
  <div class="d-flex align-items-center">
    <input matInput type="text" [readonly]="true" class="cursor-pointer" [formControl]="fileNameInputFormControl"/>
    <input #fileInput hidden (change)="onFileSelected($event)" type="file" [formControl]="fileInputFormControl" accept=".pdf,.jpg,.jpeg,.png">
    <mat-icon class="cursor-pointer p-3" matSuffix>attach_file</mat-icon>
    <mat-icon class="cursor-pointer p-3" (click)="showFile(fileInput)" *ngIf="fileNameInputFormControl.value  " matSuffix>description</mat-icon>
    <mat-icon class="cursor-pointer p-3" (click)="deleteFile($event)" *ngIf="fileNameInputFormControl.value" matSuffix>delete</mat-icon>
  </div>
  <mat-hint *ngIf="hintMessage">{{ hintMessage }}</mat-hint>
  <mat-error *ngIf="fileInputFormControl.invalid">
    {{ fileInputFormControl.errors | errorPrioritizer }}
  </mat-error>
</mat-form-field>

