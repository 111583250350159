import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ProfilBase, RechercheProfilClient } from '../../../models';
import { ProfilClientRepository } from '../../../repositories';
import { ProfilBaseService } from '../profil-base/profil-base.service';

@Injectable({
    providedIn: 'root',
})
export class CheckClientService {
    readonly READONLY_FIELDS = ['client.numAdherent', 'nom', 'prenom', 'civilite', 'dateNaissance'];
    readonly NOT_IN_FORM_FILED = ['conjoint', 'enfant'];

    constructor(
        private checkClientRepository: ProfilClientRepository,
        private profilService: ProfilBaseService,
    ) {}

    getClientDetails(rechercheClient: RechercheProfilClient): Observable<ProfilBase> {
        rechercheClient.dateNaissance.setHours(12);
        return this.checkClientRepository.search(rechercheClient).pipe(
            switchMap((profil: ProfilBase) => {
                this.profilService.formatProfilFromApi(profil);
                this.profilService.updateProfil(profil);
                return of(profil);
            }),
        );
    }
}
