<div class="conteneur-large mt-5 position-relative">
  <div class="parrainage-image">
    <div class="parrainage-contenu d-flex flex-column align-items-center ">
      <span class="parrainage-titre">
        Découvrez le parrainage SMI
      </span>
      <p class="parrainage-description mb-3">
        Faites découvrir les garanties et les avantages des produits SMI à vos proches et bénéficiez de notre offre de parrainage.
      </p>
      <app-bouton class="bouton-secondaire bouton-secondaire-blanc m-0 mb-3" [fontSize]="FontSize.XL"
        href="https://www.mutuelle-smi.com/parrainage/">
        En savoir plus
      </app-bouton>
    </div>
    <img class="parrainage-image-gauche" src="assets/images/parrainage-pour-le-parrain.svg">
    <img class="parrainage-image-droite" src="assets/images/parrainage-pour-le-filleul.svg">
  </div>
</div>
