import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class FrenchDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if (typeof value === 'number') {
            return super.parse(value);
        }

        return super.parse(this.swapDayAndMonth(value));
    }

    private swapDayAndMonth(value: any): string | null {
        if (value.length !== 10) {
            return null;
        }
        const dateToArray = value.split('/');
        if (dateToArray.length !== 3) {
            return null;
        }

        return dateToArray
            .reverse()
            .map((date: string) => {
                return Number(date);
            })
            .join('/');
    }
}
