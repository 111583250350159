import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { OffrePromotionnelle } from '../../../models';
import { OffrePromotionnelleRepository } from '../../../repositories';

@Injectable({
    providedIn: 'root',
})
export class OffrePromotionnelleService {
    protected _offrePromotionnelle: BehaviorSubject<OffrePromotionnelle> = new BehaviorSubject(<OffrePromotionnelle>{});
    public readonly offrePromotionnelle: Observable<OffrePromotionnelle> = this._offrePromotionnelle
        .asObservable()
        .pipe(filter((dds) => dds != null));

    constructor(private offrePromotionnelleRepository: OffrePromotionnelleRepository) {}

    load(): Observable<OffrePromotionnelle> {
        return this.offrePromotionnelleRepository.get().pipe(
            switchMap((offrePromotionnelle: OffrePromotionnelle) => {
                this._offrePromotionnelle.next(offrePromotionnelle);
                return this.offrePromotionnelle;
            }),
        );
    }
}
