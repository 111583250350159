import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Repository } from './repository';
import { DonneesPersonnelles, ProfilBase, RetrieveProfilResponse } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ProfilBaseRepository extends Repository {
    save(profil: ProfilBase): Observable<ProfilBase> {
        let profilResponseObservable;

        if (profil.id && 0 < profil.id) {
            profilResponseObservable = this.http.put<ProfilResponse>('/api/profil', profil);
        } else {
            profilResponseObservable = this.http.post<ProfilResponse>('/api/profil', profil);
        }

        return profilResponseObservable.pipe(
            switchMap((profileReponse) => {
                return of(profileReponse.profil);
            }),
        );
    }

    progressSave(email: string, profil: ProfilBase): Observable<any> {
        return this.http.post('/api/profil/' + profil.id + '/progressSave', { email });
    }

    retrieve(): Observable<RetrieveProfilResponse> {
        return this.http.get<RetrieveProfilResponse>('/api/profil/retrieveSave');
    }

    chooseFormule(formule: number, profil: ProfilBase): Observable<ProfilBase> {
        return this.http.post<ProfilResponse>('/api/profil/' + profil.id + '/chooseFormule', { formule: formule }).pipe(
            switchMap((ProfilResponse) => {
                return of(ProfilResponse.profil);
            }),
        );
    }

    donneesPersonnelles(profil: ProfilBase, donneesPersonnelles: DonneesPersonnelles): Observable<ProfilBase> {
        return this.http
            .post<ProfilResponse>('/api/profil/' + profil.id + '/donneesPersonnelles', donneesPersonnelles)
            .pipe(
                switchMap((ProfilResponse) => {
                    return of(ProfilResponse.profil);
                }),
            );
    }

    donneesPaiement(profil: ProfilBase, formData: FormData) {
        return this.http.post<ProfilResponse>('/api/profil/' + profil.id + '/donneesPaiement', formData).pipe(
            switchMap((ProfilResponse) => {
                return of(ProfilResponse.profil);
            }),
        );
    }

    piecesJointes(profil: ProfilBase, formData: FormData) {
        return this.http.post<ProfilResponse>('/api/profil/' + profil.id + '/piecesJointes', formData).pipe(
            switchMap((ProfilResponse) => {
                return of(ProfilResponse.profil);
            }),
        );
    }

    validationInformations(profil: ProfilBase, validationInformations: boolean) {
        return this.http
            .post<ProfilResponse>('/api/profil/' + profil.id + '/validationInformations', {
                validationInformations: validationInformations,
            })
            .pipe(
                switchMap((ProfilResponse) => {
                    return of(ProfilResponse.profil);
                }),
            );
    }

    demanderSignature(profil: ProfilBase) {
        return this.http.get<DemanderSignatureResponse>('/api/profil/' + profil.id + '/demanderSignature').pipe(
            switchMap((DemanderSignatureResponse) => {
                return of(DemanderSignatureResponse.url);
            }),
        );
    }

    verifierSignature(profil: ProfilBase) {
        return this.http.get<VerifierSignatureResponse>('/api/profil/' + profil.id + '/verifierSignature').pipe(
            switchMap((VerifierSignatureResponse) => {
                return of(VerifierSignatureResponse.statut);
            }),
        );
    }

    mettreFinAuParcours(profil: ProfilBase) {
        return this.http.post<ProfilResponse>('/api/profil/' + profil.id + '/fin', {}).pipe(
            switchMap((ProfilResponse) => {
                return of(ProfilResponse.message);
            }),
        );
    }

    checkTokenValidity(profil: ProfilBase) {
        return this.http.get<CheckValidityResponse>('/api/profil/' + profil.id + '/checkValidity');
    }

    redemanderSignature(profil: ProfilBase) {
        return this.http.post<RedemanderSignatureResponse>('/api/profil/' + profil.id + '/redemanderSignature', {});
    }
}

interface ProfilResponse {
    message: string;
    profil: ProfilBase;
}

interface DemanderSignatureResponse {
    url: string;
}

interface VerifierSignatureResponse {
    statut: string;
}

interface CheckValidityResponse {
    message: string;
}

interface RedemanderSignatureResponse {
    message: string;
}
