<div class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<h2 mat-dialog-title>
  <app-icon class="pe-2" nomIcon="telephone" size="2"></app-icon>
  <span>Nous sommes là pour vous aider</span>
</h2>
<div mat-dialog-content>
  <p>
    Nos conseillers SMI sont à votre service pour vous accompagner (et il paraît qu'ils sont sympathiques).
  </p>
  <app-bouton class="bouton-telephone bouton-primaire" [fontSize]="FontSize.M" href="tel:{{ lienNumeroContact }}" target="_blank">
    <mat-icon class="fs-2 me-3">phone</mat-icon>
    {{ numeroContact }}
  </app-bouton>
  <ng-container [ngSwitch]="origineDevis">
    <p *ngSwitchCase="'Comparateur'" class="texte-rassurance mt-0">
    Appel non surtaxé. Service ouvert du lundi au vendredi de 9h00 à 19h00.
    </p>
    <p *ngSwitchDefault class="texte-rassurance mt-0">
    Appel non surtaxé. Service ouvert du lundi au jeudi de 8h30 à 17h45 et le vendredi de 8h30 à 16h45.
    </p>
  </ng-container>
  <p class="fw-bold">
    Ou encore plus simple :
  </p>
  <app-bouton (click)="openContactezConseillerDialog()"
              class="bouton-secondaire bouton-secondaire-rouge"
              [fontSize]="FontSize.M">
    Être appelé par un conseiller
  </app-bouton>
  <p class="texte-rassurance mb-0">
    Simple et Gratuit ! Dans un délai de 24 à 48h.
  </p>
</div>
