import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { of } from 'rxjs';
import { ParrainForm } from '../../../models/form/parrain';

@Component({
    selector: 'app-parrain-dialog',
    templateUrl: './parrain-dialog.component.html',
    styleUrls: ['./parrain-dialog.component.scss'],
})
export class ParrainDialogComponent {
    parrainForm: FormGroup<ParrainForm>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { parrainForm: FormGroup<ParrainForm>; devisForm: FormGroup },
        private dialogRef: MatDialogRef<ParrainDialogComponent>,
    ) {
        this.parrainForm = this.data.parrainForm;
        of(this.data.devisForm).subscribe((form: FormGroup) => {
            if (form.get('parrain.nom')?.value) {
                this.parrainForm = <FormGroup<ParrainForm>>form.get('parrain');
            }
        });
    }

    enregisterParrain() {
        if (this.parrainForm.valid) {
            let parrain = this.parrainForm.getRawValue();
            parrain.numAdherent = String(parrain.numAdherent);
            this.dialogRef.close(parrain);
        }
    }
}
