import { Component, Inject } from '@angular/core';
import { ConfirmData } from '../../../models';
import { FontSize } from '../../../enums';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.dialog.html',
    styleUrls: ['./confirm.dialog.scss'],
})
export class ConfirmDialogComponent {
    FontSize = FontSize;
    confirmData?: ConfirmData;
    public loadingConfirm: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {
        this.confirmData = data;

        if (!this.confirmData.confirmText) {
            this.confirmData.confirmText = 'Confirmer';
        }

        if (!this.confirmData.cancelText) {
            this.confirmData.cancelText = 'Annuler';
        }
    }

    onConfirm() {
        if ('function' === typeof this.confirmData?.onConfirm) {
            this.confirmData?.onConfirm(this.dialogRef);
        }
    }

    onCancel() {
        if ('function' === typeof this.confirmData?.onCancel) {
            this.confirmData?.onCancel(this.dialogRef);
        } else {
            this.dialogRef.close();
        }
    }
}
