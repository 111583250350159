<div class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<h2 mat-dialog-title>
  <app-icon class="pe-2" nomIcon="telephone" size="2"></app-icon>
  <span>Être appelé par un conseiller</span>
</h2>
<div mat-dialog-content>
  <p *ngIf="sent; else formDemande">
    Votre demande a été prise en compte.
  </p>
  <ng-template #formDemande>
    <p>
      Saisissez vos coordonnées et l'un de nos conseillers commerciaux vous rappellera dans un délai de 24 à 48h.
    </p>
    <form [formGroup]="conseillerForm" (ngSubmit)="save()">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Nom</mat-label>
        <input matInput type="text" name="nom" formControlName="nom"/>
        <mat-error *ngIf="conseillerForm.get('nom')?.invalid">
          {{ conseillerForm.get('nom')?.errors | errorPrioritizer }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Prénom</mat-label>
        <input matInput type="text" name="prenom" formControlName="prenom"/>
        <mat-error *ngIf="conseillerForm.get('prenom')?.invalid">
          {{ conseillerForm.get('prenom')?.errors | errorPrioritizer }}
        </mat-error>
      </mat-form-field>
      <div class="mat-form-field-wrapper pb-0 w-md-100" formGroupName="numeroTelephone">
        <mat-form-field appearance="outline" class="w-50 pe-2 field-phone-prefix">
          <mat-label>Indicatif</mat-label>
          <mat-select formControlName="prefix">
            <mat-option *ngFor="let indicatif of indicatifsTelephoniques" value="{{ indicatif.value }}">
              &#43;{{ indicatif.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="conseillerForm.get('numeroTelephone')!.get('prefix')?.invalid">
            {{ conseillerForm.get('numeroTelephone')!.get('prefix')?.errors | errorPrioritizer }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-50">
          <mat-label>Téléphone</mat-label>
          <input matInput type="text" name="number" formControlName="number" (focus)="focusMobileNumber()" (blur)="blurMobileNumber()"/>
          <mat-error *ngIf="conseillerForm.get('numeroTelephone')!.get('number')?.invalid">
            {{ conseillerForm.get('numeroTelephone')!.get('number')?.errors | errorPrioritizer }}
          </mat-error>
        </mat-form-field>
      </div>
      <app-bouton class="my-4 my-md-0 bouton-primaire" [disabled]="conseillerForm.invalid || sending" [fontSize]="FontSize.L"
                  [submit]="true" [loading]="sending">
        Être appelé par un conseiller
      </app-bouton>
    </form>
  </ng-template>
  <p *ngIf="!sent" class="link-popup">
    En remplissant cette zone, je consens à être appelé par un conseiller commercial dans le cadre de ma demande.
  </p>
  <p class="link-popup">
    Vous pouvez vous inscrire gratuitement sur le registre d’opposition au démarchage téléphonique sur
    <a href="http://www.bloctel.gouv.fr/" target="_blank">www.bloctel.gouv.fr</a>.
    et consulter
    <a href="https://www.mutuelle-smi.com/protection-des-donnees/" target="_blank">ici</a>
    notre politique de protection des données personnelles
  </p>
</div>
