import { Injectable } from '@angular/core';
import { ProfilBaseRepository } from 'common-lib';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Profil } from '../models/profil';
import { QualificationDesProfils } from '../models/qualification-des-profils';
import { CollecteInfos } from '../models/collecte-infos';

@Injectable({
    providedIn: 'root',
})
export class ProfilRepository extends ProfilBaseRepository {
    qualificationDesProfils(profil: Profil, qualificationDesProfils: QualificationDesProfils): Observable<Profil> {
        if (typeof profil.origineDevis === 'undefined' && null !== localStorage.getItem('origineDevis')) {
            profil.origineDevis = localStorage.getItem('origineDevis') ?? undefined;
        }

        if (typeof profil.sourceDevis === 'undefined' && null !== localStorage.getItem('sourceDevis')) {
            profil.sourceDevis = localStorage.getItem('sourceDevis') ?? undefined;
        }

        return this.http
            .request<ProfilResponse>(profil.id ? 'PUT' : 'POST', '/api/profil', { body: qualificationDesProfils })
            .pipe(
                switchMap((ProfilResponse) => {
                    return of(ProfilResponse.profil);
                }),
            );
    }

    collecteInfos(profil: Profil, collecteInfos: CollecteInfos): Observable<Profil> {
        return this.http.post<ProfilResponse>('/api/profil/' + profil.id + '/infos', collecteInfos).pipe(
            switchMap((ProfilResponse) => {
                return of(ProfilResponse.profil);
            }),
        );
    }
}

interface ProfilResponse {
    message: string;
    profil: Profil;
}
