import { Injectable } from '@angular/core';
import { Indicatif, IndicatifTelephonique } from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    formatTel(tel: string): string {
        return tel.replace(/ /g, '');
    }

    indicatifsTelephoniques(): Indicatif[] {
        return Object.entries(IndicatifTelephonique)
            .map(([key, value]) => {
                return { key, value };
            })
            .sort((a, b) => {
                return Number.parseInt(a.value) - Number.parseInt(b.value);
            });
    }
}
