import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { OffrePromotionnelle } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OffrePromotionnelleRepository extends Repository {
    get(): Observable<OffrePromotionnelle> {
        return this.http.get<OffrePromotionnelleResponse>('/api/offre_promotionnelle').pipe(
            switchMap((OffrePromotionnelleResponse) => {
                return of(OffrePromotionnelleResponse.offrePromotionnelle);
            }),
        );
    }
}

interface OffrePromotionnelleResponse {
    message: string;
    offrePromotionnelle: OffrePromotionnelle;
}
