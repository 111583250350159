import { Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { IndicatifTelephonique, Client } from 'common-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { NaissanceBeneficiaireForm } from '../../../models/form/naissance-beneficiaire';
import { QualificationDesProfilsForm } from '../../../models/form/qualification-des-profils';
import { Profil } from '../../../models/profil';
import { QualificationDesProfils } from '../../../models/qualification-des-profils';
import { FormService } from '../form.service';

@Injectable({
    providedIn: 'root',
})
export class QualificationDesProfilsFormService extends FormService {
    protected _form: BehaviorSubject<FormGroup<QualificationDesProfilsForm>> = new BehaviorSubject(
        <FormGroup<QualificationDesProfilsForm>>{},
    );
    public readonly form: Observable<FormGroup<QualificationDesProfilsForm>> = this._form.asObservable();

    public initForm() {
        const form: FormGroup<QualificationDesProfilsForm> = this.fb.group({
            civilite: this.fb.control(this.profil?.civilite, Validators.required),
            nom: this.fb.control(
                this.profil?.nom,
                Validators.compose([
                    Validators.pattern(
                        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
                    ),
                    Validators.required,
                ]),
            ),
            prenom: this.fb.control(
                this.profil?.prenom,
                Validators.compose([
                    Validators.pattern(
                        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
                    ),
                    Validators.required,
                ]),
            ),
            dateNaissance: this.fb.control(this.profil?.dateNaissance, Validators.required),
            codePostal: this.fb.control(
                this.profil?.codePostal,
                Validators.compose([Validators.required, Validators.pattern('[0-9]{5}')]),
            ),
            ville: this.fb.control(this.profil?.ville, Validators.required),
            regimeAffiliation: this.fb.control(this.profil?.profilSoel?.regimeAffiliation, Validators.required),
            statutProfessionnel: this.fb.control(this.profil?.statutProfessionnel, Validators.required),
            mobile: this.fb.group({
                prefix: this.fb.control(this.profil?.mobile?.prefix ?? IndicatifTelephonique.FRANCE_METROPOLITAINE, [
                    Validators.required,
                ]),
                number: this.fb.control(this.profil?.mobile?.number ?? '', [Validators.required]),
            }),
            dateCouverture: this.fb.control(this.profil?.dateCouverture, Validators.required),
            accordContactTelephonique: this.fb.control(this.profil?.accordContactTelephonique ?? false),
            enfants: this.getEnfantsControl(),
        });

        if (this.profil?.profilSoel?.conjoint) {
            this.setConjointControl(form);
        }

        if (this.profil?.client) {
            this.addClientControls(form, this.profil?.client);
        } else if (!this.authenticationService.isTrustedUser()) {
            form.addControl('captcha', this.fb.control('', Validators.required));
        }

        this._form.next(form);
    }

    public isValidClient(form: FormGroup<QualificationDesProfilsForm>): boolean {
        return undefined !== form.controls.client?.controls.numAdherent.value;
    }

    public addClientControls(form: FormGroup<QualificationDesProfilsForm>, client: Client) {
        form.addControl(
            'motivationDevis',
            this.fb.control(this.profil?.profilSoel?.motivationDevis ?? null, Validators.required),
        );
        form.addControl(
            'client',
            this.fb.group({
                typeBeneficiaire: this.fb.control(client.typeBeneficiaire),
                typeContrat: this.fb.control(client.typeContrat),
                numAdherent: this.fb.control(client.numAdherent, Validators.required),
            }),
        );

        if (this.isValidClient(form)) {
            this.setDisabledOnProtectedFields(form);
        }
    }

    public setDisabledOnProtectedFields(form: FormGroup<QualificationDesProfilsForm>) {
        form.controls.civilite.disable();
        form.controls.nom.disable();
        form.controls.prenom.disable();
        form.controls.client?.controls.numAdherent.disable();
        form.controls.dateNaissance.disable();
        form.controls.ville.disable();
    }

    getEnfantsControl(): FormArray<FormGroup<NaissanceBeneficiaireForm>> {
        let enfantsControls: FormGroup<NaissanceBeneficiaireForm>[] = [];

        this.profil?.profilSoel?.enfants?.forEach((enfant) => {
            enfantsControls.push(this.getEnfantControl(enfant.dateNaissance));
        });

        return this.fb.array(enfantsControls);
    }

    getEnfantControl(defaultValue?: Date): FormGroup<NaissanceBeneficiaireForm> {
        return this.fb.group({
            dateNaissance: [defaultValue],
        });
    }

    getConjointControl(): FormGroup<NaissanceBeneficiaireForm> {
        return this.fb.group({
            dateNaissance: [this.profil?.profilSoel?.conjoint?.dateNaissance],
        });
    }

    clearForm() {
        this.initForm();
    }

    public populateProfilDataOnForm(form: FormGroup<QualificationDesProfilsForm>, profil: Profil) {
        form.controls.civilite.setValue(profil.civilite);
        form.controls.nom.setValue(profil.nom);
        form.controls.prenom.setValue(profil.prenom);
        form.controls.dateNaissance.setValue(profil.dateNaissance);
        form.controls.mobile.controls.prefix.setValue(profil.mobile?.prefix ?? '');
        form.controls.mobile.controls.number.setValue(profil.mobile?.number ?? '');
        form.controls.dateCouverture.setValue(profil.dateCouverture);
        form.controls.codePostal.setValue(profil.codePostal);
        form.controls.ville.setValue(profil.ville);
        form.controls.regimeAffiliation.setValue(profil.profilSoel?.regimeAffiliation);
        form.controls.statutProfessionnel.setValue(profil.statutProfessionnel);
    }

    public fromProfilFormToQualificationDesProfils(): QualificationDesProfils {
        const qualificationDesProfils = <QualificationDesProfils>this._form.value.getRawValue();

        if (
            typeof qualificationDesProfils.mobile !== 'undefined' &&
            typeof qualificationDesProfils.mobile.number !== 'undefined' &&
            qualificationDesProfils.mobile.number !== null
        ) {
            qualificationDesProfils.mobile.number = qualificationDesProfils.mobile.number.replace(/^0/, '');
        }

        if (this.profil.profilSoel) {
            if (this.profil.profilSoel.niveauBesoin) {
                qualificationDesProfils.niveauBesoin = this.profil.profilSoel.niveauBesoin;
            }

            qualificationDesProfils.has100Sante = this.profil.profilSoel.has100Sante;
        }

        return qualificationDesProfils;
    }

    public setConjointControl(form: FormGroup<QualificationDesProfilsForm>) {
        if (!form.controls.conjoint) {
            form.addControl('conjoint', this.getConjointControl());
        }
    }
}
