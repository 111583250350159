import { Component, Input, NgIterable } from '@angular/core';
import { Argument } from '../../../models';

@Component({
    selector: 'app-liste-arguments',
    templateUrl: './liste-arguments.component.html',
    styleUrls: ['./liste-arguments.component.scss'],
})
export class ListeArgumentsComponent {
    @Input() tableauArguments?: NgIterable<Argument> = [];
}
