import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { AuthenticationRepository, TokenResponse } from '../../repositories';

interface User {
    exp: number;
    user: string;
    trusted: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private static readonly AUTH_TOKEN_LOCALSTORAGE_KEY = 'authToken';
    private static readonly REFRESH_TOKEN_LOCALSTORAGE_KEY = 'refreshToken';
    private static readonly REFRESH_TOKEN_EXP_DATE_LOCALSTORAGE_KEY = 'refreshTokenExpDate';

    private jwtHelperService: JwtHelperService;
    private isGettingToken: Boolean = false;
    private tokenSubject: Subject<string> = new Subject<string>();
    public token$: Observable<string> = this.tokenSubject.asObservable();

    constructor(private authenticationRepository: AuthenticationRepository) {
        this.jwtHelperService = new JwtHelperService();
    }

    async getAccessToken(): Promise<string> {
        return new Promise((resolve) => {
            if (this.isGettingToken) {
                this.tokenSubject.subscribe((value) => {
                    resolve(value);
                });
            } else {
                this.isGettingToken = true;

                const accessToken = this.getAccessTokenFromLocalStorage();
                let refreshToken: string | undefined = <string>this.getRefreshTokenFromLocalStorage();

                if (accessToken != null) {
                    if (this.isAccessTokenValid(accessToken)) {
                        this.isGettingToken = false;
                        return resolve(accessToken);
                    }

                    const refreshTokenExpirationDate = this.getRefreshTokenExpirationDateFromLocalStorage();
                    if (refreshToken.length > 0 && !this.isRefreshTokenValid(refreshTokenExpirationDate)) {
                        refreshToken = undefined;
                    }
                }

                this.getNewTokens(refreshToken)
                    .then((tokens) => {
                        if (accessToken && !refreshToken) {
                            localStorage.clear();
                        }
                        this.saveTokensInLocalStorage(tokens);
                        this.tokenSubject.next(tokens.accessToken);
                        resolve(tokens.accessToken);
                    })
                    .finally(() => {
                        this.isGettingToken = false;
                    });
            }
        });
    }

    private isAccessTokenValid(token: string): boolean {
        return !this.jwtHelperService.isTokenExpired(token);
    }

    public isTrustedUser(): boolean {
        const token = this.getAccessTokenFromLocalStorage();
        if (null === token) {
            return false;
        }

        const user = this.jwtHelperService.decodeToken<User>(token);

        return user.trusted;
    }

    getTokenFromProgressAndCode(progress: string, code: string): Observable<TokenResponse> {
        this.logout();
        localStorage.setItem('progress', progress);
        localStorage.setItem('codeSms', code);
        return this.authenticationRepository.getAccessToken('');
    }

    getAuthTokensFromTokenResponse(tokenResponse: TokenResponse): AuthTokens {
        return {
            accessToken: tokenResponse.data.token,
            refreshToken: tokenResponse.data.refreshToken,
            refreshTokenExpireAt: tokenResponse.data.refreshTokenExpireAt,
        };
    }

    private async getNewTokens(refreshToken?: string): Promise<AuthTokens> {
        const response = <TokenResponse>await this.authenticationRepository.getAccessToken(refreshToken).toPromise();

        return this.getAuthTokensFromTokenResponse(response);
    }

    private isRefreshTokenValid(refreshTokenExpirationDate: Date | null): boolean {
        const now = new Date();
        return refreshTokenExpirationDate !== null && refreshTokenExpirationDate >= now;
    }

    private getAccessTokenFromLocalStorage(): string | null {
        return localStorage.getItem(AuthenticationService.AUTH_TOKEN_LOCALSTORAGE_KEY);
    }

    private getRefreshTokenFromLocalStorage(): string | null {
        return localStorage.getItem(AuthenticationService.REFRESH_TOKEN_LOCALSTORAGE_KEY);
    }

    private getRefreshTokenExpirationDateFromLocalStorage(): Date | null {
        const rawDate = localStorage.getItem(AuthenticationService.REFRESH_TOKEN_EXP_DATE_LOCALSTORAGE_KEY);
        if (rawDate !== null) {
            return new Date(JSON.parse(rawDate));
        } else {
            return null;
        }
    }

    private saveTokensInLocalStorage(tokens: AuthTokens) {
        localStorage.setItem(AuthenticationService.AUTH_TOKEN_LOCALSTORAGE_KEY, tokens.accessToken);
        localStorage.setItem(AuthenticationService.REFRESH_TOKEN_LOCALSTORAGE_KEY, tokens.refreshToken);
        localStorage.setItem(
            AuthenticationService.REFRESH_TOKEN_EXP_DATE_LOCALSTORAGE_KEY,
            JSON.stringify(tokens.refreshTokenExpireAt),
        );
    }

    public logout() {
        localStorage.removeItem(AuthenticationService.AUTH_TOKEN_LOCALSTORAGE_KEY);
        localStorage.removeItem(AuthenticationService.REFRESH_TOKEN_LOCALSTORAGE_KEY);
        localStorage.removeItem(AuthenticationService.REFRESH_TOKEN_EXP_DATE_LOCALSTORAGE_KEY);
    }

    public async tryRefreshToken() {
        const refreshToken = this.getRefreshTokenFromLocalStorage();
        const refreshTokenExpirationDate = this.getRefreshTokenExpirationDateFromLocalStorage();
        if (refreshToken === null) {
            throw new Error('Refresh token away from LocalStorage');
        }
        if (!this.isRefreshTokenValid(refreshTokenExpirationDate)) {
            throw new Error('Refresh token has expired');
        }
        return await this.getNewTokens(refreshToken);
    }

    saveTokenResponseInLocalStorage(tokenResponse: TokenResponse) {
        this.saveTokensInLocalStorage(this.getAuthTokensFromTokenResponse(tokenResponse));
    }
}

interface AuthTokens {
    accessToken: string;
    refreshToken: string;
    refreshTokenExpireAt: Date;
}
