import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DonneesPersonnelles, ProfilBase, RetrieveProfilResponse, Tarif } from '../../../models';
import { ProfilBaseRepository } from '../../../repositories';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ProfilBaseService {
    private static readonly ISO_DATE_REGEX =
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

    protected _profil: BehaviorSubject<ProfilBase> = new BehaviorSubject(<ProfilBase>{});
    public readonly profil: Observable<ProfilBase> = this._profil.asObservable();

    constructor(
        protected profilRepository: ProfilBaseRepository,
        protected authenticationService: AuthenticationService,
    ) {}

    protected saveLocalProfil(profil: ProfilBase) {
        localStorage.setItem('profil', JSON.stringify(profil));
    }

    private getLocalProfil(): ProfilBase {
        const serializedProfil = localStorage.getItem('profil');
        return this.formatSerializedProfil(serializedProfil);
    }

    protected formatSerializedProfil(serializedProfil: string | null) {
        let localProfil = this.defaultProfil();

        if (serializedProfil) {
            try {
                localProfil = JSON.parse(serializedProfil, (key, value) => {
                    if (ProfilBaseService.ISO_DATE_REGEX.test(value)) {
                        value = new Date(value);
                    }
                    return value;
                });
            } catch (e) {
                console.error('An error occured while deserializing Profil', e);
            }
        }

        return localProfil;
    }

    protected restoreLocalProfil() {
        let localProfil = this.getLocalProfil();
        this.updateProfil(localProfil);
    }

    saveProfil(profil: ProfilBase): Observable<ProfilBase> {
        if (typeof profil.origineDevis === 'undefined' && null !== localStorage.getItem('origineDevis')) {
            profil.origineDevis = localStorage.getItem('origineDevis') ?? undefined;
        }

        if (typeof profil.sourceDevis === 'undefined' && null !== localStorage.getItem('sourceDevis')) {
            profil.sourceDevis = localStorage.getItem('sourceDevis') ?? undefined;
        }

        return this.sendProfilToDatabase(profil).pipe(
            switchMap((profil) => {
                this.saveLocalProfil(profil);
                localStorage.removeItem('origineDevis');
                localStorage.removeItem('sourceDevis');
                return this.profil;
            }),
        );
    }

    protected defaultProfil(): ProfilBase {
        return {};
    }

    private sendProfilToDatabase(profil: ProfilBase): Observable<ProfilBase> {
        this.formatForApi(profil);
        return this.profilRepository.save(profil).pipe(
            switchMap((profilSaved: ProfilBase) => {
                this.formatProfilFromApi(profilSaved);
                this.updateProfil(profilSaved);
                return this.profil;
            }),
        );
    }

    public async retrieveProfilFromProgressOrReprise(
        paramName: string,
        parameter: string,
        code?: string,
    ): Promise<RetrieveProfilResponse> {
        localStorage.setItem(paramName, parameter);
        if ('progress' === paramName && code) {
            localStorage.setItem('codeSms', code);
        }
        this.authenticationService.logout();
        return await this.profilRepository
            .retrieve()
            .toPromise()
            .then((data: RetrieveProfilResponse) => {
                if (data.profil) {
                    this.formatProfilFromApi(data.profil);
                    this.updateProfil(data.profil);
                }

                return data;
            })
            .finally(() => {
                localStorage.removeItem(paramName);
                localStorage.removeItem('codeSms');
            });
    }

    public formatProfilFromApi(profil: ProfilBase) {
        if (profil.dateNaissance) {
            profil.dateNaissance = new Date(profil.dateNaissance);
        }
        if (profil.dateCouverture) {
            profil.dateCouverture = new Date(profil.dateCouverture);
        }
    }

    public formatForApi(profil: ProfilBase) {
        if (profil.dateNaissance) {
            profil.dateNaissance.setHours(12);
        }
        if (profil.dateCouverture) {
            profil.dateCouverture.setHours(12);
        }
    }

    chooseFormule(formule: number, profil: ProfilBase): Observable<ProfilBase> {
        this.formatForApi(profil);
        return this.profilRepository.chooseFormule(formule, profil).pipe(
            switchMap((profilSaved: ProfilBase) => {
                this.formatProfilFromApi(profilSaved);
                this.updateProfil(profilSaved);
                return this.profil;
            }),
        );
    }

    updateProfil(profil: ProfilBase) {
        this._profil.next(profil);
        this.saveLocalProfil(profil);
    }

    getTarifChoisi(profil: ProfilBase): Tarif | undefined {
        return profil.offre?.tarifs.filter((tarif: Tarif) => {
            return tarif.choisi;
        })[0];
    }

    donneesPersonnelles(profil: ProfilBase, donneesPersonnelles: DonneesPersonnelles): Observable<ProfilBase> {
        if (!donneesPersonnelles.conjoint?.nom) {
            donneesPersonnelles.conjoint = undefined;
        }

        return this.profilRepository.donneesPersonnelles(profil, donneesPersonnelles).pipe(
            switchMap((profil: ProfilBase) => {
                this.formatProfilFromApi(profil);
                this.updateProfil(profil);
                return this.profil;
            }),
        );
    }

    donneesPaiement(profil: ProfilBase, formData: FormData): Observable<ProfilBase> {
        return this.profilRepository.donneesPaiement(profil, formData).pipe(
            switchMap((profil: ProfilBase) => {
                this.formatProfilFromApi(profil);
                this.updateProfil(profil);
                return this.profil;
            }),
        );
    }

    piecesJointes(profil: ProfilBase, formData: FormData): Observable<ProfilBase> {
        return this.profilRepository.piecesJointes(profil, formData).pipe(
            switchMap((profil: ProfilBase) => {
                this.formatProfilFromApi(profil);
                this.updateProfil(profil);
                return this.profil;
            }),
        );
    }

    validationInformations(profil: ProfilBase, validationInformations: boolean): Observable<ProfilBase> {
        return this.profilRepository.validationInformations(profil, validationInformations).pipe(
            switchMap((profil: ProfilBase) => {
                this.formatProfilFromApi(profil);
                this.updateProfil(profil);
                return this.profil;
            }),
        );
    }

    demanderSignature(profil: ProfilBase): Observable<string> {
        return this.profilRepository.demanderSignature(profil);
    }

    verifierSignature(profil: ProfilBase): Observable<string> {
        return this.profilRepository.verifierSignature(profil);
    }

    clearProfil() {
        this.updateProfil(this.defaultProfil());
        localStorage.clear();
    }

    mettreFinAuParcours(profil: ProfilBase): Observable<string> {
        return this.profilRepository.mettreFinAuParcours(profil);
    }

    checkTokenValidity(profil: ProfilBase): Observable<any> {
        return this.profilRepository.checkTokenValidity(profil);
    }

    redemanderSignature(profil: ProfilBase): Observable<any> {
        return this.profilRepository.redemanderSignature(profil);
    }

    getOrigineDevis(): string {
        let localOrigine = localStorage.getItem('origineDevis');
        if (null !== localOrigine) {
            return localOrigine;
        }

        let localProfil = this.getLocalProfil();

        return localProfil.origineDevis ?? '';
    }

    public saveProfilFromSwitchMap = switchMap((profil: ProfilBase) => {
        this.formatProfilFromApi(profil);
        this.updateProfil(profil);
        return this.profil;
    });
}
