import { Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import contains from '@popperjs/core/lib/dom-utils/contains';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { FontSize } from '../../../enums';
import { FrenchDateAdapter } from '../../../locale';
import { ProfilBase, RechercheProfilClient } from '../../../models';
import { AuthenticationService, CheckClientService } from '../../../services';

@Component({
    selector: 'app-vos-infos-client',
    templateUrl: './vos-infos-client.dialog.html',
    styleUrls: ['./vos-infos-client.component.scss'],
    providers: [{ provide: DateAdapter, useClass: FrenchDateAdapter }],
})
export class VosInfosClientComponent implements OnDestroy {
    FontSize = FontSize;
    clientForm: FormGroup<ClientForm>;
    maxDateAnniversaire: Date;
    minDateAnniversaire: Date;
    checkClientSending: boolean = false;
    private subscriptions = new Subscription();
    @Output() mobilePresent = new EventEmitter();
    @ViewChild('recaptcha') recaptcha?: RecaptchaComponent;

    constructor(
        private dateAdapter: DateAdapter<Date>,
        private dialogRef: MatDialogRef<VosInfosClientComponent>,
        private checkClientService: CheckClientService,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public data: EntryClientFormData,
    ) {
        this.dateAdapter.setLocale('fr');
        if (data.minDateAnniversaire && data.maxDateAnniversaire) {
            this.minDateAnniversaire = data.minDateAnniversaire;
            this.maxDateAnniversaire = data.maxDateAnniversaire;
        } else {
            this.maxDateAnniversaire = new Date();
            this.minDateAnniversaire = new Date();
            this.maxDateAnniversaire.setFullYear(this.maxDateAnniversaire.getFullYear() - 18);
            this.minDateAnniversaire.setFullYear(this.minDateAnniversaire.getFullYear() - 120);
        }

        this.clientForm = this.fb.group({
            nom: this.fb.control('', Validators.required),
            prenom: this.fb.control('', Validators.required),
            dateNaissance: this.fb.control(data.dateNaissance, Validators.required),
            numAdherent: this.fb.control('', Validators.required),
        });

        if (!this.authenticationService.isTrustedUser()) {
            this.clientForm.addControl('captcha', this.fb.control('', Validators.required));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    validerClient(): void {
        if (this.clientForm.valid) {
            this.checkClientSending = true;

            let rechercheProfilClient = <RechercheProfilClient>this.clientForm.getRawValue();

            if (null !== localStorage.getItem('origineDevis')) {
                rechercheProfilClient.origineDevis = localStorage.getItem('origineDevis') ?? undefined;
            }

            if (null !== localStorage.getItem('sourceDevis')) {
                rechercheProfilClient.sourceDevis = localStorage.getItem('sourceDevis') ?? undefined;
            }

            rechercheProfilClient = {
                ...rechercheProfilClient,
                ...this.data.extra,
            };

            this.subscriptions.add(
                this.checkClientService.getClientDetails(rechercheProfilClient).subscribe(
                    (profil: ProfilBase) => {
                        this.checkClientSending = false;
                        this.dialogRef.close(profil);
                    },
                    () => {
                        this.checkClientSending = false;
                        this.recaptcha?.reset();
                    },
                ),
            );
        }
    }

    onCaptchaResolve(key: string) {
        this.clientForm.get('captcha')?.setValue(key);
    }
}

interface ClientForm {
    nom: FormControl<string | null>;
    prenom: FormControl<string | null>;
    dateNaissance: FormControl<Date | null>;
    numAdherent: FormControl<string | null>;
    captcha?: FormControl<string | null>;
}

interface EntryClientFormData {
    dateNaissance: Date;
    minDateAnniversaire?: Date;
    maxDateAnniversaire?: Date;
    extra: [];
}
