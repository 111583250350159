import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../../environments/environment';
import { DomaineDeSimulation, Parrain, ProfilBase, TypeDePrestation } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class GtmMappageService {
    constructor(protected gtmService: GoogleTagManagerService) {}

    pushPageReady(url: string) {
        this.gtmService.pushTag({
            event: 'page_ready',
            user_id: this.getUserId(),
            user_device: this.getDeviceType(),
            content_group: this.getContentGroupFromUrl(url),
            page_name: this.getPageNameFromUrl(url),
            environment: environment.production ? 'Prod' : 'Dev',
            user_origin: this.getUserOrigin(),
        });
    }

    pushValidationSituation(profil: ProfilBase) {
        this.gtmService.pushTag({
            event: 'situation_validation',
            client_type: this.getClientTypeByProfil(profil),
        });
    }

    pushChoiceOfQuote(profil: ProfilBase | undefined, formule: number) {
        this.gtmService.pushTag({
            event: 'choice_of_quote',
            client_type: this.getClientTypeByProfil(profil),
            quote_type: this.getQuoteTypeByProfil(profil, formule),
        });
    }

    pushClickRefundSimulation(
        profil: ProfilBase | undefined,
        domaineDeSimulationSelectionne: DomaineDeSimulation | undefined,
        typeDePrestationSelectionne: TypeDePrestation,
    ) {
        this.gtmService.pushTag({
            event: 'click_refund_simulation',
            client_type: this.getClientTypeByProfil(profil),
            care_area: domaineDeSimulationSelectionne?.libelle,
            type_of_services: typeDePrestationSelectionne.libelle,
        });
    }

    pushSendQuoteProposal(profil: ProfilBase | undefined, parrain: Parrain | undefined) {
        this.gtmService.pushTag({
            event: 'send_quote_proposal',
            client_type: this.getClientTypeByProfil(profil),
            quote_type: this.getQuoteTypeByProfil(profil),
            sponsorship: parrain ? 'oui' : 'non',
        });
    }

    pushSaveSearch() {
        this.gtmService.pushTag({
            event: 'save_search',
        });
    }

    pushSubscriptionIntention(profil: ProfilBase | undefined) {
        this.gtmService.pushTag({
            event: 'subscription_intention',
            client_type: this.getClientTypeByProfil(profil),
            quote_type: this.getQuoteTypeByProfil(profil),
        });
    }

    pushReturnSmiWebsite() {
        this.gtmService.pushTag({
            event: 'return_smi_website',
        });
    }

    pushSubscriptionValidation(profil: ProfilBase | undefined) {
        this.gtmService.pushTag({
            event: 'subscription_validation',
            client_type: this.getClientTypeByProfil(profil),
            quote_type: this.getQuoteTypeByProfil(profil),
        });
    }

    private getQuoteTypeByProfil(profil: ProfilBase | undefined, forceFormule: number | undefined = undefined): string {
        const tarifs = profil?.offre?.tarifs;
        if (tarifs) {
            for (const indexTarif in tarifs) {
                if (
                    (forceFormule && tarifs[indexTarif].formule.ordre === forceFormule) ||
                    (!forceFormule && tarifs[indexTarif].choisi)
                ) {
                    switch (indexTarif) {
                        case '0':
                            return 'Moins cher';
                        case '1':
                            return tarifs.length > 2 ? 'Intermédiaire' : 'Plus cher';
                        case '2':
                            return 'Plus cher';
                    }
                }
            }
        }

        return '';
    }
    protected getClientTypeByProfil(profil: ProfilBase | undefined): string {
        return profil?.client ? 'Client SMI' : 'Pas client SMI';
    }

    private getDeviceType() {
        const devices = {
            desktop: 1024,
            tablette: 512,
            mobile: 0,
        };

        for (const device in devices) {
            // @ts-ignore
            if (window.screen.width >= devices[device]) {
                return device;
            }
        }

        return '';
    }

    private getUserId() {
        const cookies = <{ _gid: string }>this.getCookies();
        return cookies._gid?.split('.')[2] ?? '';
    }

    private getCookies() {
        let cookiesFormated = {};
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const cookieInfo = cookie.split('=');
            if (cookieInfo.length === 2) {
                // @ts-ignore
                cookiesFormated[cookieInfo[0]] = cookieInfo[1];
            }
        }

        return cookiesFormated;
    }

    private getUserOrigin() {
        let origin = '';

        if (localStorage.getItem('source_produit_smi')) {
            origin = 'mutuelle-smi.com - ' + localStorage.getItem('source_produit_smi');
        } else {
            origin = localStorage.getItem('sourceDevis') || '';
            if (localStorage.getItem('origineDevis')) {
                origin = (origin ? origin + ' - ' : '') + localStorage.getItem('origineDevis');
            }
        }

        return origin;
    }

    private getContentGroupFromUrl(url: string) {
        const args = url.replace(/^\//, '').split('/');

        if (args.length === 1) {
            return 'situation';
        } else {
            return args[0];
        }
    }

    private getPageNameFromUrl(url: string) {
        const args = url.replace(/^\//, '').split('/');

        if (args.length === 1) {
            return '' === args[0] ? 'besoins' : args[0];
        } else {
            return args[1];
        }
    }
}
