import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepriseRepository } from '../../../repositories';
import { RepriseResponse } from '../../../models';

@Injectable({
    providedIn: 'root',
})
export class RepriseService {
    constructor(private repriseRepository: RepriseRepository) {}

    smsMessage(progress: string): Observable<RepriseResponse> {
        return this.repriseRepository.smsMessage(progress);
    }

    resendSms(progress: string): Observable<boolean> {
        return this.repriseRepository.resendSms(progress);
    }
}
