import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ContactezConseillerRepository } from '../../../repositories';
import { DemandeRappelConseiller } from '../../../models';

@Injectable({
    providedIn: 'root',
})
export class ContactezConseillerService {
    protected _sent: BehaviorSubject<boolean> = new BehaviorSubject(<boolean>{});
    public readonly sent: Observable<boolean> = this._sent.asObservable().pipe(filter((dds) => dds != null));

    constructor(private contactezConseillerRepository: ContactezConseillerRepository) {}

    sendDemandeRappelConseiller(demandeRappelConseiller: DemandeRappelConseiller): Observable<boolean> {
        return this.contactezConseillerRepository.send(demandeRappelConseiller).pipe(
            switchMap((value) => {
                this._sent.next(value);
                return this.sent;
            }),
        );
    }
}
