<div class="d-flex flex-column align-items-center justify-content-center py-5 background-gris">
  <span class="infos-devis-titre">Votre devis en toute tranquillité</span>

  <ul class="d-flex flex-column flex-md-row">
    <li *ngFor="let zoneDevis of zonesDevis">
      <app-icon [nomIcon]="zoneDevis.icon" [size]="'5'" [color]="Couleur.BLANC" [stroke]="true"></app-icon>
      <span class="infos-sous-titre">{{zoneDevis.text}}</span>
    </li>
  </ul>
</div>

