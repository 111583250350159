import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UtmEnum, QueryParams, SnackbarComponent, SnackStatus, RetrieveProfilResponse } from 'common-lib';
import { QualificationDesProfilsFormService } from './services/form/qualification-des-profils/qualification-des-profils-form.service';
import { ProfilService } from './services/profil/profil.service';
import { Profil } from './models/profil';
import { GtmMappageService } from './services/core/gtm-mappage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    UtmEnum = UtmEnum;
    title = 'soel-front';

    constructor(
        private router: Router,
        private profilService: ProfilService,
        private gtmService: GoogleTagManagerService,
        private matSnackBar: MatSnackBar,
        private qualificationDesProfilsFormService: QualificationDesProfilsFormService,
        private gtmMappageService: GtmMappageService,
    ) {
        if (document.referrer) localStorage.setItem('referer', document.referrer);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            const url = (<NavigationEnd>event).url;
            this.gtmMappageService.pushPageReady(url);
            if (!url.match(/^\/reprise/)) {
                localStorage.setItem('lastScreen', url);
            }
        });
    }

    ngOnInit(): void {
        const params = this.parseQuery(window.location.search);
        if (params.reset === '1') {
            this.profilService.clearProfil();
        }

        let redirectScreen = localStorage.getItem('lastScreen') ?? '/';
        if ('/webcallback' === window.location.pathname) {
            localStorage.setItem('showCallback', '1');
        }

        if (params[UtmEnum.source]) {
            if ('mutuelle-smi' == params[UtmEnum.source] && params[UtmEnum.campaign]) {
                localStorage.setItem('source_produit_smi', params[UtmEnum.campaign]);
            } else {
                localStorage.setItem('origineDevis', params[UtmEnum.source]);
            }
        } else {
            if (typeof params.origine !== 'undefined') {
                localStorage.setItem('origineDevis', params.origine);
            }
            if (typeof params.source !== 'undefined') {
                localStorage.setItem('sourceDevis', params.source);
            }
        }

        if (params.reset === '1') {
            return this.hardRedirectToHome();
        }

        if (environment.production) {
            this.gtmService.addGtmToDom();
        }

        let paramType: string | undefined = undefined;
        if (params.progress) {
            paramType = 'progress';
        } else if (params.reprise) {
            paramType = 'reprise';
        }

        if (paramType) {
            this.profilService
                .retrieveProfilFromProgressOrReprise(paramType, params[<keyof QueryParams>paramType])
                .then((data: RetrieveProfilResponse) => {
                    this.qualificationDesProfilsFormService.setApiErrors(data.errors);
                    this.qualificationDesProfilsFormService.clearForm();
                    if (data.screen) {
                        this.redirectTo(data.screen);
                    } else {
                        this.redirectTo(redirectScreen);
                    }
                });
        } else {
            this.redirectTo(redirectScreen);
        }

        this.profilService.profil
            .subscribe((profil: Profil) => {
                if (profil.id) {
                    if (!paramType) {
                        this.profilService
                            .checkTokenValidity(profil)
                            .toPromise()
                            .then(() => {});
                    }
                } else {
                    if (localStorage.getItem('expiredSession')) {
                        this.matSnackBar.openFromComponent(SnackbarComponent, {
                            data: {
                                status: SnackStatus.INFO,
                                message: 'Votre session a expiré.',
                            },
                            duration: 5000,
                        });
                        localStorage.removeItem('expiredSession');
                    }
                }
            })
            .unsubscribe();
    }

    private redirectTo(uri: string) {
        if (uri !== window.location.pathname && '/reprise' !== window.location.pathname) {
            this.router.navigate([uri]);
        }
    }

    private parseQuery(queryString: string): QueryParams {
        var query = <QueryParams>{};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[<keyof QueryParams>decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    private hardRedirectToHome(): void {
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    }
}
