import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    ConfigService,
    BaseUrlInterceptor,
    ErrorHandlerInterceptor,
    AuthenticationService,
    CookiebotConfig,
    CommonLibModule,
    ProfilBaseService,
    ZONES_DEVIS,
    BREADCRUMBS_STEPS,
} from 'common-lib';
import { MatIconModule } from '@angular/material/icon';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFR from '@angular/common/locales/fr';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Step } from './enums/step.enum';
import { ZoneDevisRepository } from './repositories/zone-devis.repository';
import { ProfilService } from './services/profil/profil.service';

export function jwtOptionsFactory(authenticationService: AuthenticationService, configService: ConfigService) {
    return {
        tokenGetter: async () => {
            return await authenticationService.getAccessToken();
        },
        disallowedRoutes: [
            configService.apiUrl + '/api/token',
            configService.apiUrl + '/api/refresh_token',
            configService.apiUrl + '/api/sms_message',
        ],
        allowedDomains: [configService.apiDomain],
    };
}

export function initGTM(configService: ConfigService): string {
    return configService.gtmID;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonLibModule,
        AppRoutingModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        HttpClientModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthenticationService, ConfigService],
            },
        }),
        NoopAnimationsModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatButtonModule,
        CommonLibModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseUrlInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: NgxCookiebotConfig,
            useClass: CookiebotConfig,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        ConfigService,
        ProfilService,
        {
            provide: ProfilBaseService,
            useExisting: ProfilService,
        },
        {
            provide: 'googleTagManagerId',
            useFactory: initGTM,
            deps: [ConfigService],
        },
        {
            provide: ZONES_DEVIS,
            useFactory: ZoneDevisRepository.getZoneDevis,
        },
        {
            provide: BREADCRUMBS_STEPS,
            useValue: Object.values(Step),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeFR);
    }
}
