import { Injectable } from '@angular/core';
import { Couleur, FontSize } from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class CssService {
    formatCssVar(variable: string): string {
        return `var(--${variable})`;
    }

    formatCssColorVar(color: Couleur): string {
        return this.formatCssVar(color);
    }

    formatCssFontVar(fontSize: FontSize): string {
        return this.formatCssVar(`font${fontSize}`);
    }
}
