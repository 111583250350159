<div class="d-flex flex-row align-items-center mt-4 {{ !garantie.active ? 'garantie-disabled' : '' }}" (click)="toggleContent()">
  <div *ngIf=garantie.domaineDeSoin?.nom class="d-flex align-items-center pe-1">
    <app-icon nomIcon="{{ garantie.domaineDeSoin.nom }}" size="1.8"></app-icon>
  </div>
  <span class="domaine-de-soin-offre-nom">
    {{ garantie.domaineDeSoin.titre }}
  </span>
  <div *ngIf="garantie.niveau !== null" class="domaine-de-soin-offre-points d-flex justify-content-between align-items-center">
    <div *ngFor="let index of [1,2,3,4]">
      <div class="domaine-de-soin-offre-point {{ index <= garantie.niveau && garantie.active ? 'domaine-de-soin-offre-point-actif' : '' }}"></div>
    </div>
  </div>
  <mat-icon *ngIf="!opened; else lessButton" class="align-middle">expand_more</mat-icon>
  <ng-template #lessButton>
    <mat-icon class="align-middle">expand_less</mat-icon>
  </ng-template>
</div>
<div *ngIf="opened">
  <p class="domaine-de-soin-offre-contenu" [innerHTML]="garantie.description"></p>
</div>
