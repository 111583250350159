import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FontSize } from '../../../enums';
import { CssService } from '../../../services/css/css.service';

@Component({
    selector: 'app-bouton',
    templateUrl: './bouton.component.html',
    styleUrls: ['./bouton.component.scss'],
})
export class BoutonComponent implements AfterViewInit {
    @Input() fontSize: FontSize = FontSize.M;
    @Input() disabled: boolean = false;
    @Input() submit: boolean = false;
    @Input() href?: string;
    @Input() routerLink?: string;
    @Input() target?: string;
    @Input() loading: boolean = false;

    private cssService: CssService;
    private ref: ElementRef;

    constructor(cssService: CssService, ref: ElementRef) {
        this.ref = ref;
        this.cssService = cssService;
    }

    ngAfterViewInit(): void {
        this.applyFontSize(this.fontSize);
    }

    protected applyFontSize(fontSize: FontSize): string | void {
        let currentComponent = this.ref.nativeElement.querySelector('.elementPrincipal');
        if (currentComponent) {
            return (currentComponent.style.fontSize = this.cssService.formatCssFontVar(fontSize));
        }
    }
}
