import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RecaptchaModule } from 'ng-recaptcha';
import { ContactezNousDialogComponent } from './contactez-nous/contactez-nous.dialog';
import { ReprendrePlusTardComponent } from './reprendre-plus-tard/reprendre-plus-tard.component';
import { ContactezConseillerDialogComponent } from './contactez-conseiller/contactez-conseiller.dialog';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../../pipes/pipes.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VosInfosClientComponent } from './vos-infos-client/vos-infos-client.dialog';
import { ParrainDialogComponent } from './parrain-dialog/parrain-dialog.component';
import { ConfirmDialogComponent } from './confirm/confirm.dialog';

@NgModule({
    declarations: [
        ContactezConseillerDialogComponent,
        ContactezNousDialogComponent,
        ReprendrePlusTardComponent,
        VosInfosClientComponent,
        ParrainDialogComponent,
        ConfirmDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        PipesModule,
        MatDatepickerModule,
        RecaptchaModule,
        MatIconModule,
    ],
    exports: [
        ReprendrePlusTardComponent,
        ContactezConseillerDialogComponent,
        ContactezNousDialogComponent,
        VosInfosClientComponent,
        ConfirmDialogComponent,
    ],
})
export class CoreDialogsModule {}
