<div class="offre-conteneur {{ preconise ? 'offre-recommande' : '' }} mt-5">
  <app-picto *ngIf="preconise" class="offre-tampon" nomPicto="tampon-bleu" [taille]="TaillePicto.S"></app-picto>
  <div class="offre-contenu">
    <span class="offre-pre-titre">
      {{ pretitre }}
    </span>
    <span class="offre-titre {{ !lien && !pretitre ? 'my-4' : '' }}">
      {{ titre }}
    </span>
    <span *ngIf="lien" class="offre-lien">
      <a href="{{ lien }}" target="_blank">
        Visualiser toutes les garanties
      </a>
    </span>
    <div class="offre-details p-3 mb-5">
    <ng-content></ng-content>
      <app-bouton class="{{ boutonSecondaire ? 'bouton-secondaire' : 'bouton-primaire' }} mb-0"
                  (click)="onClicBouton()"
                  [loading]="loading"
                  [fontSize]="boutonSecondaire ? FontSize.S : FontSize.M">
        {{ etiquetteBouton }}
      </app-bouton>
    </div>
  </div>
</div>
