import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    private configService: ConfigService;

    constructor(configService: ConfigService) {
        this.configService = configService;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.startsWith('/api')) {
            const apiReq = request.clone({ url: this.configService.apiUrl + request.url });
            return next.handle(apiReq);
        } else {
            return next.handle(request);
        }
    }
}
