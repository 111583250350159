import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Offre } from '../../../models';
import { OffreRepository } from '../../../repositories';

@Injectable({
    providedIn: 'root',
})
export class OffreService {
    protected _offre: BehaviorSubject<Offre> = new BehaviorSubject(<Offre>{});
    public readonly offre: Observable<Offre> = this._offre.asObservable().pipe(filter((dds) => dds != null));

    constructor(private offreRepository: OffreRepository) {}

    load(): Observable<Offre> {
        return this.offreRepository.get().pipe(
            switchMap((offre: Offre) => {
                this._offre.next(offre);
                return this.offre;
            }),
        );
    }
}
