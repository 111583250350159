<div [formGroup]="devisForm">
    <hr *ngIf="canViewParrainage" />
    <div class="w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
        <div class="colonne-gauche d-flex flex-row pb-3 w-md-33 justify-content-center justify-content-md-start mt-2 my-md-0 pe-3 {{ canViewParrainage ? '' : 'no-content' }}">
            <app-icon class="icon-titre-section-formulaire" nomIcon="parrain" [stroke]="true"></app-icon>
            <span class="titre-section-formulaire">Informations parrainage</span>
        </div>
        <div class="vos-informations w-100 w-md-66 d-flex flex-column flex-md-row flex-wrap">
            <ng-container *ngIf="canViewParrainage">
                <app-boite-parrain *ngIf="devisForm.get('parrain.nom')?.value; else switchParrain;"
                                   (openDialogEvent)="openParrainDialog()" (removeParrainEvent)="removeParrain()"
                                   [collecteInfosForm]="devisForm"></app-boite-parrain>

                <ng-template #switchParrain>
                    <div class="pb-2 w-100">
                        <span class="infos-client">Êtes-vous parrainé ?
                            <a #popoverParrain *ngIf="canViewPopover" href="#" (click)="$event.preventDefault()" data-bs-toggle="popover"
                               data-bs-content="L’offre de parrainage s’applique hors offre DUO ou offre promotionnelle.">
                                <mat-icon class="icon-popover">info_outline</mat-icon>
                            </a>
                        </span>
                    </div>
                    <div class="w-100 d-flex">
                        <div class="mt-1 mat-form-field-wrapper w-100 w-md-50 pe-md-2">
                            <mat-button-toggle-group class="w-100" [(value)]="haveParrain" (change)="changeParrain($event)">
                                <mat-button-toggle class="py-1 w-100" [value]="true">Oui</mat-button-toggle>
                                <mat-button-toggle class="py-1 w-100" [value]="false">Non</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="py-1 px-2"><div class="fake-icon-space"></div></div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>

    <mat-checkbox class="mt-3" formControlName="confirmeValiditeEmail">
        Je déclare que l'adresse e-mail transmise est valide et me permet de recevoir les informations légales
        précontractuelles et contractuelles via un support durable.
    </mat-checkbox>
    <mat-error *ngIf="devisForm.get('confirmeValiditeEmail')?.invalid" class="w-100 ms-5 mt-3 text-start">
        {{ devisForm.get('confirmeValiditeEmail')?.errors | errorPrioritizer }}
    </mat-error>
    <mat-checkbox formControlName="demarchage" class="mt-3">
        En cochant cette case, j'accepte de recevoir par voie électronique des informations concernant les produits
        et services de SMI.
    </mat-checkbox>
</div>
