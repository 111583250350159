import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormBaseService, AuthenticationService, FormValidationService, MobilePhoneNumberValidator } from 'common-lib';
import { Profil } from '../../models/profil';
import { ProfilService } from '../profil/profil.service';

@Injectable({
    providedIn: 'root',
})
export class FormService extends FormBaseService {
    constructor(
        protected profilService: ProfilService,
        protected fb: FormBuilder,
        protected formValidationService: FormValidationService,
        protected mobilePhoneNumberValidator: MobilePhoneNumberValidator,
        protected authenticationService: AuthenticationService,
    ) {
        super(profilService, fb, formValidationService, mobilePhoneNumberValidator, authenticationService);
        this.subscriptions.add(
            this.profilService.profil.subscribe((profil: Profil) => {
                this.profilObject = profil;
            }),
        );
    }

    get profil(): Profil {
        return <Profil>this.profilObject;
    }
}
