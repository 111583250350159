import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ParrainDialogComponent } from '../../dialogs/parrain-dialog/parrain-dialog.component';
import { Parrain } from '../../../models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ParrainForm } from '../../../models/form/parrain';
import { Popover } from 'bootstrap';

@Component({
    selector: 'app-finalisation-devis',
    templateUrl: './finalisation-devis.component.html',
    styleUrls: ['./finalisation-devis.component.scss'],
})
export class FinalisationDevisComponent implements AfterViewInit {
    public haveParrain?: boolean = false;

    @Input() canViewParrainage: boolean = true;
    @Input() canViewPopover: boolean = true;
    @Input() devisForm!: FormGroup;
    @Input() parrainControls!: FormGroup<ParrainForm>;

    @ViewChild('popoverParrain') popoverParrain?: ElementRef;

    constructor(private dialog: MatDialog) {}

    ngAfterViewInit() {
        if (this.popoverParrain) {
            new Popover(this.popoverParrain.nativeElement, {
                placement: 'top',
                container: 'body',
                trigger: 'focus',
                html: true,
            });
        }
    }

    removeParrain() {
        this.devisForm.removeControl('parrain');
        this.haveParrain = false;
    }

    openParrainDialog() {
        this.openDialog();
    }

    changeParrain($event: MatButtonToggleChange) {
        if ($event.value) {
            this.openDialog();
        }
    }

    openDialog() {
        const dialogParrain = this.dialog.open(ParrainDialogComponent, {
            data: {
                parrainForm: this.parrainControls,
                devisForm: this.devisForm,
            },
        });
        dialogParrain.afterClosed().subscribe((parrain: Parrain | null) => {
            if (!parrain) {
                this.haveParrain = false;
            } else {
                let parrainFormGroup = <FormGroup<ParrainForm>>this.devisForm.get('parrain');

                if (!parrainFormGroup || !parrainFormGroup.controls) {
                    parrainFormGroup = this.parrainControls;
                }
                parrainFormGroup.controls.nom.setValue(parrain.nom);
                parrainFormGroup.controls.prenom.setValue(parrain.prenom);
                parrainFormGroup.controls.numAdherent.setValue(parrain.numAdherent);
                if (parrain.email) {
                    parrainFormGroup.controls.email.setValue(parrain.email);
                }
                this.devisForm.setControl('parrain', parrainFormGroup);
            }
        });
    }
}
