import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { IndicatifTelephonique, Racines } from '../enums';

@Injectable({
    providedIn: 'root',
})

/**
 * @classdesc Détermine la validité d'un numéro de téléphone mobile en examinant
 * l'IndicatifTelephonique et la Racine
 */
export class MobilePhoneNumberValidator {
    public validate(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const numberField: AbstractControl | null = formGroup.get('number');
            if (numberField === null) {
                return null;
            }

            if (!this.isMobile(numberField.value)) {
                return null;
            }

            const regex = this.getMobilePhoneRegex(<IndicatifTelephonique>formGroup.get('prefix')?.value);

            if (!numberField.value.match(regex)) {
                numberField.setErrors({ numberAndIndicative: true });
            } else if (formGroup.get('number')?.hasError('numberAndIndicative')) {
                numberField.setErrors(null);
                numberField.updateValueAndValidity();
            }

            return null;
        };
    }

    private isMobile(number: string): boolean {
        return !!number.match(/^0(6|7)/);
    }

    /**
     * @example
     * // Pour Mayotte et La Réunion, returns /^(0639|0692|0693)/
     * this.getMobilePhoneRegex(262)
     * @return RegExp expression régulière battie à partir de la liste des racines
     * autorisées pour un indicatif particulier
     */
    private getMobilePhoneRegex(prefix: IndicatifTelephonique): RegExp {
        const racines = Racines[prefix];

        return new RegExp(`^(${racines.join('|')})`);
    }
}
