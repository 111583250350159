import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DomaineDeSoin } from '../models';

@Injectable({
    providedIn: 'root',
})
export abstract class DomaineDeSoinServiceInterface {
    public abstract readonly domainesDeSoin: Observable<DomaineDeSoin[]>;

    abstract load(): Observable<DomaineDeSoin[]>;
}
