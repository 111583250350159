import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProfilBase } from '../../../models';
import { FormValidationService, GtmMappageService, ProfilBaseService, RepriseParcoursService } from '../../../services';
import { FontSize } from '../../../enums';

@Component({
    selector: 'app-reprendre-plus-tard',
    templateUrl: './reprendre-plus-tard.component.html',
    styleUrls: ['./reprendre-plus-tard.component.scss'],
})
export class ReprendrePlusTardComponent implements OnDestroy {
    FontSize = FontSize;
    saveForm: UntypedFormGroup;
    saving: boolean = false;
    success: boolean = false;
    profil?: ProfilBase;
    private subscriptions = new Subscription();

    constructor(
        private fb: UntypedFormBuilder,
        private repriseParcoursService: RepriseParcoursService,
        private profilService: ProfilBaseService,
        private formValidationService: FormValidationService,
        private gtmMappageService: GtmMappageService,
    ) {
        this.saveForm = this.fb.group({
            email: fb.control('', [Validators.required, Validators.email]),
        });

        this.subscriptions.add(
            this.profilService.profil.subscribe((profil: ProfilBase) => {
                this.profil = profil;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    save() {
        if (this.saveForm.valid && this.profil) {
            this.saving = true;
            this.gtmMappageService.pushSaveSearch();
            this.repriseParcoursService
                .saveParcours(this.saveForm.controls.email.value, this.profil)
                .pipe(
                    catchError((response: HttpErrorResponse) => {
                        if (response.error && response.error.errors) {
                            this.formValidationService.populateFieldsWithErrors(this.saveForm, response.error.errors);

                            this.saving = false;
                            return EMPTY;
                        }
                        return throwError(response);
                    }),
                )
                .subscribe(
                    (value) => {
                        this.success = true;
                    },
                    () => {
                        this.saving = false;
                    },
                );
        }
    }
}
