import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-boite-parrain',
    templateUrl: './boite-parrain.component.html',
    styleUrls: ['./boite-parrain.component.scss'],
})
export class BoiteParrainComponent {
    @Input() collecteInfosForm?: FormGroup;

    @Output() openDialogEvent = new EventEmitter<boolean>();
    @Output() removeParrainEvent = new EventEmitter<boolean>();

    openDialog() {
        this.openDialogEvent.next(true);
    }

    supprimerParrain() {
        this.removeParrainEvent.next(false);
    }
}
