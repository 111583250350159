import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DomaineDeSoin } from '../../../models';
import { DomaineDeSoinRepository } from '../../../repositories';
import { DomaineDeSoinServiceInterface } from '../../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class DomaineDeSoinService implements DomaineDeSoinServiceInterface {
    protected _domainesDeSoin: BehaviorSubject<DomaineDeSoin[]> = new BehaviorSubject(new Array<DomaineDeSoin>());
    public readonly domainesDeSoin: Observable<DomaineDeSoin[]> = this._domainesDeSoin
        .asObservable()
        .pipe(filter((dds) => dds != null));

    constructor(private domaineDeSoinRepository: DomaineDeSoinRepository) {}

    load(): Observable<DomaineDeSoin[]> {
        return this.domaineDeSoinRepository.getAll().pipe(
            switchMap((datas) => {
                this._domainesDeSoin.next(datas);
                return this.domainesDeSoin;
            }),
        );
    }
}
