<header class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <div class="icon-header-block">
      <a (click)="drawer?.toggle()" class="icon-header-noir icon-header-menu">
        <mat-icon *ngIf="!drawer?.opened">menu</mat-icon>
        <span *ngIf="!drawer?.opened">Menu</span>

        <mat-icon *ngIf="drawer?.opened">clear</mat-icon>
        <span *ngIf="drawer?.opened">Fermer</span>
      </a>
    </div>
  </div>
  <div class="conteneur-logo d-flex flex-grow-1 justify-content-md-center">
      <app-picto class="mx-2 ms-md-0" nomPicto="logo-smi-rvb" [taille]="TaillePicto.S"></app-picto>
  </div>
  <div class="d-flex align-items-center justify-content-end">
    <div class="icon-header-block">
      <a (click)="openReprendrePlusTardDialog()"
         *ngIf="repriseParcoursService.repriseAvailable | async"
         class="icon-header-rouge icon-header-enregistrer">
        <mat-icon>save</mat-icon>
        <span class="icon-header-label">Reprendre plus tard</span>
      </a>
    </div>
    <div class="icon-header-block">
      <button (click)="openContactezNousDialog()" class="icon-header-noir">
        <mat-icon>phone</mat-icon>
        <span class="icon-header-label">Contactez-nous</span>
      </button>
    </div>
  </div>
</header>
