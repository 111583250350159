<div class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<h2 mat-dialog-title class="d-flex align-items-center">
  <app-icon class="pe-2 d-flex align-items-center" nomIcon="infos-client" size="3" [stroke]="true"></app-icon>
  <span>Vos infos client</span>
</h2>
<div mat-dialog-content [formGroup]="clientForm">
  <p>
    <img class="w-100 p-3 pt-0" src="assets/images/visuel-carte-tiers-payant.jpg" alt="Carte tiers payant SMI" >
    Merci de saisir vos informations telles qu'elles sont inscrites sur votre carte de tiers payant
  </p>

  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nom</mat-label>
      <input matInput type="text" formControlName="nom"/>
      <mat-error *ngIf="clientForm.controls.nom.invalid">
        {{ clientForm.controls.nom.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Prénom</mat-label>
      <input matInput type="text" formControlName="prenom"/>
      <mat-error *ngIf="clientForm.controls.prenom.invalid">
        {{ clientForm.controls.prenom.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="pe-md-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Date de naissance</mat-label>
      <div class="d-flex align-items-center justify-content-between">
        <input matInput [matDatepicker]="pickerNaissanceClient" [max]="maxDateAnniversaire" formControlName="dateNaissance" [min]="minDateAnniversaire">
        <mat-datepicker-toggle [for]="pickerNaissanceClient"></mat-datepicker-toggle>
        <mat-datepicker #pickerNaissanceClient></mat-datepicker>
      </div>
      <mat-error *ngIf="clientForm.controls.dateNaissance.invalid">
        {{ clientForm.controls.dateNaissance.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Numéro d'adhérent</mat-label>
      <input matInput type="text" formControlName="numAdherent"/>
      <mat-error *ngIf="clientForm.controls.numAdherent.invalid">
        {{ clientForm.controls.numAdherent.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>

  <re-captcha *ngIf="clientForm.contains('captcha')" #recaptcha (resolved)="onCaptchaResolve($event)" class="mx-auto mb-3"></re-captcha>

  <app-bouton (click)="validerClient()" class="bouton-primaire" [fontSize]="FontSize.M" [disabled]="clientForm.invalid" [loading]="checkClientSending">
    Valider
  </app-bouton>
</div>
