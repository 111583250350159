import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProfilBaseRepository } from '../../repositories';
import { ProfilBase } from '../../models';
import { ProfilBaseService } from '../api/profil-base/profil-base.service';

@Injectable({
    providedIn: 'root',
})
export class RepriseParcoursService implements OnDestroy {
    private static readonly REPRISE_AVAILABLE_URLS = [
        '/ma-situation',
        '/ma-situation/definition-des-besoins',
        '/devis/mon-offre',
        '/devis/mes-infos',
        '/devis/confirmation',
        '/souscription/mes-donnees',
        '/souscription/mes-donnees-de-paiement',
        '/souscription/mes-documents',
        '/validation/mes-informations',
        '/validation/signature',
    ];

    public repriseAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private profil?: ProfilBase;
    private currentUrl!: string;
    private subscriptions = new Subscription();

    constructor(
        private profilRepository: ProfilBaseRepository,
        @Inject(ProfilBaseService) protected profilService: ProfilBaseService,
        private router: Router,
    ) {
        this.subscriptions.add(
            this.profilService.profil.subscribe((profil: ProfilBase) => {
                this.profil = profil;
                this.updateRepriseAvailable();
            }),
        );
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                this.updateRepriseAvailable();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    saveParcours(email: string, profil: ProfilBase): Observable<any> {
        return this.profilRepository.progressSave(email, profil);
    }

    private updateRepriseAvailable() {
        const idProfil = this.profil?.id;
        const index = RepriseParcoursService.REPRISE_AVAILABLE_URLS.indexOf(this.currentUrl);

        if (
            RepriseParcoursService.REPRISE_AVAILABLE_URLS[index] === '/devis/confirmation' &&
            !this.profil?.souscriptionPossible
        ) {
            this.repriseAvailable.next(false);
        } else {
            const available = index > -1 && undefined !== this.profil && undefined !== idProfil && 0 < idProfil;
            this.repriseAvailable.next(available);
        }
    }
}
