import { NgModule } from '@angular/core';
import { ErrorPrioritizerPipe } from './error-prioritizer/error-prioritizer.pipe';
import { FormatNiemePipe } from './format-nieme/format-nieme.pipe';
import { RawHtmlPipe } from './raw-html/raw-html.pipe';
import { SafePipe } from './safe/safe.pipe';

@NgModule({
    declarations: [ErrorPrioritizerPipe, FormatNiemePipe, RawHtmlPipe, SafePipe],
    imports: [],
    exports: [ErrorPrioritizerPipe, FormatNiemePipe, RawHtmlPipe, SafePipe],
})
export class PipesModule {}
