import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { DemandeRappelConseiller } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ContactezConseillerRepository extends Repository {
    send(demandeRappelConseiller: DemandeRappelConseiller): Observable<boolean> {
        return this.http.post<ContactezConseillerResponse>('/api/callback', demandeRappelConseiller).pipe(
            switchMap((ContactezConseillerResponse) => {
                return of(ContactezConseillerResponse.sent);
            }),
        );
    }
}

interface ContactezConseillerResponse {
    message: string;
    sent: boolean;
}
