import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormValidationService {
    constructor() {}

    public populateFieldsWithErrors(form: UntypedFormGroup, errors: object) {
        const errorsBySelector = this.getErrorsBySelector(errors);
        let scrolled = false;

        errorsBySelector.forEach((ebs) => {
            let field = <UntypedFormControl>form.get(ebs.fieldSelector);
            if (!field) {
                return;
            }

            field.setErrors({
                api: ebs.errors.length > 1 ? ebs.errors : ebs.errors[0],
            });
            field.markAsTouched();
            if (!scrolled) {
                const fieldName = ebs.fieldSelector.split('.').pop();

                // L'élément est récupéré via le querySelector en attendant une meilleur solution de la part d'Angular
                // @see(https://github.com/angular/angular/issues/18025)
                const fieldElement = <HTMLElement>document.querySelector(`[formcontrolname="${fieldName}"]`);

                if (fieldElement) {
                    setTimeout(() => {
                        fieldElement.focus();
                    }, 200);
                    scrolled = true;
                }
            }
        });
    }

    private getErrorsBySelector(errors: any): ErrorBySelector[] {
        const errorsBySelector: ErrorBySelector[] = [];

        const champsEnErreur = Object.keys(errors);

        champsEnErreur.forEach((champ) => {
            if (Array.isArray(errors[champ])) {
                errorsBySelector.push({
                    fieldSelector: champ,
                    errors: errors[champ],
                });
            } else if ('0' !== champ) {
                errorsBySelector.push(
                    ...this.getErrorsBySelector(errors[champ]).map((value) => {
                        return <ErrorBySelector>{
                            fieldSelector: champ + '.' + value.fieldSelector,
                            errors: value.errors,
                        };
                    }),
                );
            }
        });

        return errorsBySelector;
    }
}

interface ErrorBySelector {
    fieldSelector: string;
    errors: string[];
}
