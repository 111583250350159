import { Component, Inject, InjectionToken } from '@angular/core';
import { Couleur } from '../../../enums';
import { ZoneDevis } from '../../../models';

@Component({
    selector: 'app-zone-devis',
    templateUrl: './zone-devis.component.html',
    styleUrls: ['./zone-devis.component.scss'],
})
export class ZoneDevisComponent {
    Couleur = Couleur;
    public zonesDevis: ZoneDevis[] = [];

    constructor(@Inject(ZONES_DEVIS) zonesDevis: ZoneDevis[] = []) {
        this.zonesDevis = zonesDevis;
    }
}

export const ZONES_DEVIS = new InjectionToken<ZoneDevis[]>('');
