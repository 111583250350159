import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Configuration, APP_CONFIG } from 'common-lib';

if (environment.production) {
    enableProdMode();
}

fetch('assets/config/configuration.json')
    .then((response) => response.json())
    .then((configuration: Configuration) => {
        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: configuration }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    })
    .catch((err) => console.error(err));
