import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DomaineDeSoin, Garantie } from '../../../models';
import { StringService } from '../../../services/string/string.service';

@Component({
    selector: 'app-domaine-soin-offre',
    templateUrl: './domaine-soin-offre.component.html',
    styleUrls: ['./domaine-soin-offre.component.scss'],
})
export class DomaineSoinOffreComponent {
    @Input() garantie: Garantie = <Garantie>{ domaineDeSoin: <DomaineDeSoin>{} };
    @Input() opened: boolean = false;
    @Output() toggleOppened = new EventEmitter<DomaineSoinOffreComponent>();

    private stringService: StringService;

    constructor(stringService: StringService) {
        this.stringService = stringService;
    }

    toggleContent() {
        if (this.garantie.active) {
            this.opened = !this.opened;
            this.toggleOppened.next(this);
        }
    }
}
