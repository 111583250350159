import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { DomaineDeSoin } from '../models';

@Injectable({
    providedIn: 'root',
})
export class DomaineDeSoinRepository extends Repository {
    getAll(): Observable<DomaineDeSoin[]> {
        return this.http.get<DomaineDeSoinResponse>('/api/domaine_de_soin').pipe(
            switchMap((DomaineDeSoinResponse) => {
                return of(DomaineDeSoinResponse.domainesDeSoins);
            }),
        );
    }
}

interface DomaineDeSoinResponse {
    message: string;
    domainesDeSoins: DomaineDeSoin[];
}
