<div class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<h2 mat-dialog-title>
  <app-icon class="pe-2" nomIcon="parrain" [stroke]="true" size="3"></app-icon>
  <span>Vos informations de parrainage</span>
</h2>
<div mat-dialog-content [formGroup]="parrainForm">
  <div class="mat-form-field-wrapper pb-0 mt-3">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nom</mat-label>
      <input matInput type="text" formControlName="nom"/>
      <mat-error *ngIf="parrainForm.controls.nom.invalid">
        {{ parrainForm.controls.nom.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mat-form-field-wrapper pb-0">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Prénom</mat-label>
      <input matInput type="text" formControlName="prenom"/>
      <mat-error *ngIf="parrainForm.controls.prenom.invalid">
        {{ parrainForm.controls.prenom.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mat-form-field-wrapper pb-0">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>N° d'adhérent du parrain</mat-label>
      <input matInput type="number" formControlName="numAdherent" min="0"/>
      <mat-error *ngIf="parrainForm.controls.numAdherent.invalid">
        {{ parrainForm.controls.numAdherent.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mat-form-field-wrapper pb-0">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Adresse email <span class="optionnel">(optionnel)</span></mat-label>
      <input matInput type="text" formControlName="email"/>
      <mat-error *ngIf="parrainForm.controls.email.invalid">
        {{ parrainForm.controls.email.errors | errorPrioritizer }}
      </mat-error>
    </mat-form-field>
  </div>
  <app-bouton (click)="enregisterParrain()" class="bouton-primaire" [disabled]="parrainForm.invalid">
    Enregistrer
  </app-bouton>
</div>
