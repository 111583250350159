<!-- header -->
<app-header [drawer]="drawer"></app-header>
<!-- / header -->

<mat-drawer-container class="app-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'">
    <app-menu></app-menu>
  </mat-drawer>
  <mat-drawer-content>
    <main>
      <!-- routes will be rendered here -->
      <router-outlet></router-outlet>
    </main>

    <!-- footer -->
    <app-footer></app-footer>
    <!-- / footer -->
  </mat-drawer-content>
</mat-drawer-container>
