import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { Offre } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OffreRepository extends Repository {
    get(): Observable<Offre> {
        return this.http.get<OffreResponse>('/api/offre').pipe(
            switchMap((OffreResponse) => {
                return of(OffreResponse.offre);
            }),
        );
    }
}

interface OffreResponse {
    message: string;
    offre: Offre;
}
