import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Repository } from './repository';
import { ProfilBase, RechercheClient } from '../models';

@Injectable({
    providedIn: 'root',
})
export class CheckClientRepository extends Repository {
    search(rechercheClient: RechercheClient): Observable<ProfilBase> {
        return this.http.post<ClientResponse>('/api/profil_client', rechercheClient).pipe(
            switchMap((ClientResponse) => {
                return of(ClientResponse.profil);
            }),
        );
    }
}

interface ClientResponse {
    message: string;
    profil: ProfilBase;
}
