import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactezConseillerService } from '../../../services/api/contactez-conseiller/contactez-conseiller.service';
import { FormValidationService, ProfilBaseService } from '../../../services';
import { MobilePhoneNumberValidator } from '../../../validators';
import { UtilsService } from '../../../services/utils/utils.service';
import { DemandeRappelConseiller } from '../../../models';
import { FontSize, IndicatifTelephonique } from '../../../enums';

interface Indicatif {
    key: string;
    value: string;
}

@Component({
    selector: 'app-contactez-conseiller',
    templateUrl: './contactez-conseiller.dialog.html',
    styleUrls: ['./contactez-conseiller.dialog.scss'],
})
export class ContactezConseillerDialogComponent implements OnInit {
    FontSize = FontSize;
    conseillerForm!: UntypedFormGroup;
    sending: boolean = false;
    sent: boolean = false;
    IndicatifTelephonique = IndicatifTelephonique;
    indicatifsTelephoniques: Indicatif[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private contactezConseillerService: ContactezConseillerService,
        private formValidationService: FormValidationService,
        private profilService: ProfilBaseService,
        private mobilePhoneNumberValidator: MobilePhoneNumberValidator,
        utilsService: UtilsService,
    ) {
        this.indicatifsTelephoniques = utilsService.indicatifsTelephoniques(); //.keys(IndicatifTelephonique)
    }

    ngOnInit(): void {
        this.conseillerForm = this.fb.group({
            nom: ['', Validators.required],
            prenom: ['', Validators.required],
            numeroTelephone: this.getTelephoneControl(),
        });

        this.profilService.profil.subscribe((value) => {
            this.conseillerForm.addControl(
                'origine',
                this.fb.control(value?.origineDevis ?? localStorage.getItem('origineDevis') ?? ''),
            );
        });
    }

    save() {
        this.sending = true;
        const demandeRappel = <DemandeRappelConseiller>this.conseillerForm.getRawValue();
        if (
            typeof demandeRappel.numeroTelephone !== 'undefined' &&
            typeof demandeRappel.numeroTelephone.number !== 'undefined'
        ) {
            demandeRappel.numeroTelephone.number = demandeRappel.numeroTelephone.number.replace(/^0/, '');
        }

        this.contactezConseillerService
            .sendDemandeRappelConseiller(demandeRappel)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    if (response.error && response.error.errors) {
                        this.formValidationService.populateFieldsWithErrors(this.conseillerForm, response.error.errors);

                        this.sending = false;
                        return EMPTY;
                    }
                    return throwError(response);
                }),
            )
            .subscribe(
                (value) => {
                    if (value) {
                        this.sending = false;
                        this.sent = true;
                    }
                },
                () => {
                    this.sending = false;
                },
            );
    }

    private getTelephoneControl(): UntypedFormGroup {
        return this.fb.group(
            {
                number: ['', Validators.compose([Validators.required, Validators.pattern(/^0[1-9][0-9]{8}$/)])],
                prefix: [IndicatifTelephonique.FRANCE_METROPOLITAINE, Validators.required],
            },
            { validators: [this.mobilePhoneNumberValidator.validate()], updateOn: 'blur' },
        );
    }

    focusMobileNumber() {
        if (this.conseillerForm.get('numeroTelephone')!.get('number')?.value === '') {
            this.conseillerForm.get('numeroTelephone')!.get('number')?.setValue('0');
        }
    }

    blurMobileNumber() {
        if (this.conseillerForm.get('numeroTelephone')!.get('number')?.value === '0') {
            this.conseillerForm.get('numeroTelephone')!.get('number')?.setValue('');
        }
    }
}
