import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProfilBaseService } from '../../../services';
import { ComponentType } from '@angular/cdk/overlay';
import { Profil } from '../../../models/profil';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-boite-offre',
    templateUrl: './boite-offre.component.html',
    styleUrls: ['./boite-offre.component.scss'],
})
export class BoiteOffreComponent implements AfterViewInit, OnDestroy {
    public sousTitre: string = '';
    public montantTotal: number = 0;

    @Input() titre?: string;
    @Input() updatable: boolean = true;
    @Input() profil?: Profil;
    @Input() dialogOffre!: ComponentType<any>;

    private subscriptions = new Subscription();

    constructor(
        private dialog: MatDialog,
        private profilBaseService: ProfilBaseService,
    ) {}

    ngAfterViewInit() {
        if (this.profil) {
            let tarif = this.profilBaseService.getTarifChoisi(this.profil);
            if (tarif) {
                if (!this.titre) {
                    this.titre = tarif.formule.nom;
                } else {
                    this.sousTitre = tarif.formule.nom;
                }
                this.montantTotal = tarif.montantTotal;
            }
        }
    }

    openOffreDialog() {
        this.dialog.open(this.dialogOffre, { data: { updatable: this.updatable, profil: this.profil } });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
