<div *ngIf="!confirmData?.hideCloseIcon" class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<h2 mat-dialog-title class="d-flex align-items-center">
  <app-icon *ngIf="confirmData?.icon" class="pe-2 d-flex align-items-center" nomIcon="{{ confirmData?.icon }}" size="3" [stroke]="true"></app-icon>
  <span>{{ confirmData?.title }}</span>
</h2>
<div mat-dialog-content>
  <p>{{ confirmData?.description }}</p>
  <app-bouton (click)="onConfirm()" class="bouton-primaire" [fontSize]="FontSize.M" [loading]="loadingConfirm">
    {{ confirmData?.confirmText }}
  </app-bouton>
  <app-bouton *ngIf="!confirmData?.hideCancel" (click)="onCancel()" class="bouton-secondaire" [fontSize]="FontSize.M">
    {{ confirmData?.cancelText }}
  </app-bouton>
</div>
