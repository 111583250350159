import { Injectable } from '@angular/core';
import { ZoneDevis } from 'common-lib';

@Injectable({
    providedIn: 'root',
})
export class ZoneDevisRepository {
    public static getZoneDevis(): ZoneDevis[] {
        return [
            {
                icon: 'inscription-simple',
                text: 'Une adhésion simple, 100% en ligne',
            },
            {
                icon: '30jours',
                text: 'Une couverture adaptée à vos besoins et votre budget',
            },
            {
                icon: 'conseiller',
                text: 'Des conseillers disponibles pour mieux vous accompagner',
            },
        ];
    }
}
