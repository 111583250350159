import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'mes-besoins',
        pathMatch: 'full',
    },
    {
        path: 'mes-besoins',
        loadChildren: () => import('./modules/mes-besoins/mes-besoins.module').then((m) => m.MesBesoinsModule),
    },
    {
        path: 'ma-situation',
        loadChildren: () => import('./modules/situation/situation.module').then((m) => m.SituationModule),
    },
    {
        path: 'devis',
        loadChildren: () => import('./modules/devis/devis.module').then((m) => m.DevisModule),
    },
    {
        path: 'souscription',
        loadChildren: () => import('./modules/souscription/souscription.module').then((m) => m.SouscriptionModule),
    },
    {
        path: 'validation',
        loadChildren: () => import('./modules/validation/validation.module').then((m) => m.ValidationModule),
    },
    {
        path: 'reprise',
        loadChildren: () => import('./modules/reprise/reprise.module').then((m) => m.RepriseModule),
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
