<div class="w-100 m-1">
  <div class="boite">
    <div class="d-flex flex-row align-items-center">
      <app-icon nomIcon="parrain" [stroke]="true"></app-icon>
      <span class="titre-boite ms-2">Parrain</span>
    </div>
    <div class="w-100 d-flex justify-content-between my-2">
      <p class="infos-parrain">
        {{ collecteInfosForm?.get('parrain.prenom')?.value }} {{ collecteInfosForm?.get('parrain.nom')?.value | uppercase }} <br>
        Adhérent n°{{ collecteInfosForm?.get('parrain.numAdherent')?.value }}
        <span *ngIf="collecteInfosForm?.get('parrain.email')?.value">
          <br>{{ collecteInfosForm?.get('parrain.email')?.value }}
        </span>
      </p>
    </div>
    <div class="d-flex flex-row align-items-center">
      <app-bouton (click)="openDialog()" class="bouton-lien pe-4">Modifier</app-bouton>
      <app-bouton (click)="supprimerParrain()" class="bouton-lien">Supprimer</app-bouton>
    </div>
  </div>
</div>
