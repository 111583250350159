import { Repository } from './repository';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MotifDevisRepository extends Repository {
    getMotifForTypes(typeBeneficiaire: string, typeContrat: string): string[] {
        let type = 'assure_principal_individuel';

        if ('type_beneficiaire' === typeBeneficiaire) {
            type = 'beneficiaire';
        } else if ('typecontrat_collectif' === typeContrat) {
            type = 'assure_principal_collectif';
        }

        return MotifDevisRepository.getMotifs()[<keyof MotifsDeDevis>type];
    }

    private static getMotifs(): MotifsDeDevis {
        return <MotifsDeDevis>{
            assure_principal_individuel: ['Changement de complémentaire santé', 'Évolution du niveau de garanties'],
            assure_principal_collectif: [
                'Départ en retraite',
                'Licenciement pour faute lourde',
                'Fin de période de portabilité',
                'Démission',
                'Départ de l’entreprise',
                'Fin de période Evin',
                'Résiliation Evin',
                'Autre motif',
            ],
            beneficiaire: [
                'Décès de l’assuré principal',
                'Fin de scolarité',
                'Limite d’âge',
                'Résiliation du contrat dont je suis ayant droit',
            ],
        };
    }
}

interface MotifsDeDevis {
    assure_principal_individuel: string[];
    assure_principal_collectif: string[];
    beneficiaire: string[];
}
