<div *ngIf="offrePromotionnelle" class="bandeau-offre position-relative w-100">
  <div class="conteneur-large d-flex justify-content-center align-items-center">
    <div class="w-50 pe-2 d-flex justify-content-end align-items-center">
      <p class="nom-bandeau-offre m-0">
        Offre<br/>exceptionnelle
      </p>
    </div>
    <div class="bloc-bandeau-offre w-50">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <p class="titre-bandeau-offre m-0">
          {{ offrePromotionnelle.titre }}
        </p>
        <p class="sous-titre-bandeau-offre m-0">
          {{ offrePromotionnelle.sousTitre }}
        </p>
        <app-bouton *ngIf="offrePromotionnelle.lienCondition" class="bouton-lien bouton-lien-blanc d-block" href="{{ offrePromotionnelle.lienCondition }}" target="_blank">
          Conditions de l'offre
        </app-bouton>
      </div>
    </div>
  </div>
  <mat-icon (click)="closeOffrePromotionnelle()" class="close-icon">clear</mat-icon>
</div>
