import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProfilBase } from '../../models';
import { MobilePhoneNumberValidator } from '../../validators';
import { ProfilBaseService } from '../api/profil-base/profil-base.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { FormValidationService } from './form-validation.service';

@Injectable({
    providedIn: 'root',
})
export abstract class FormBaseService implements OnDestroy {
    public profilObject?: ProfilBase;

    protected subscriptions = new Subscription();
    protected apiErrors?: object;

    constructor(
        @Inject(ProfilBaseService) protected profilService: ProfilBaseService,
        protected fb: FormBuilder,
        protected formValidationService: FormValidationService,
        protected mobilePhoneNumberValidator: MobilePhoneNumberValidator,
        protected authenticationService: AuthenticationService,
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    apiValidator(control: FormControl): ValidationErrors | null {
        if (control.getError('api')) {
            return { api: control.getError(`api`) };
        }
        return null;
    }

    populateErrors(form: FormGroup) {
        if (this.apiErrors) {
            this.formValidationService.populateFieldsWithErrors(form, this.apiErrors);
            this.apiErrors = undefined;
        }
    }

    setApiErrors(errors?: Object) {
        this.apiErrors = errors;
    }

    get profil(): ProfilBase | undefined {
        return this.profilObject;
    }
}
