import { Component, OnInit } from '@angular/core';
import { OffrePromotionnelle } from '../../../models';
import { OffrePromotionnelleService } from '../../../services/api/offre-promotionnelle/offre-promotionnelle.service';

@Component({
    selector: 'app-bandeau-offre',
    templateUrl: './bandeau-offre.component.html',
    styleUrls: ['./bandeau-offre.component.scss'],
})
export class BandeauOffreComponent implements OnInit {
    offrePromotionnelle?: OffrePromotionnelle;

    constructor(private offrePromotionnelleService: OffrePromotionnelleService) {}

    ngOnInit(): void {
        this.offrePromotionnelleService.load().subscribe((offrePromotionnelle: OffrePromotionnelle) => {
            if (offrePromotionnelle.titre) {
                this.offrePromotionnelle = offrePromotionnelle;
            }
        });
    }

    closeOffrePromotionnelle() {
        this.offrePromotionnelle = undefined;
    }
}
