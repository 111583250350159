import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TaillePicto } from '../../../enums';
import { RepriseParcoursService } from '../../../services';
import {
    ContactezConseillerDialogComponent,
    ContactezNousDialogComponent,
    ReprendrePlusTardComponent,
} from '../../dialogs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
    TaillePicto = TaillePicto;

    @Input() drawer?: MatDrawer;

    constructor(
        private dialog: MatDialog,
        public repriseParcoursService: RepriseParcoursService,
    ) {
        this.dialog = dialog;
    }

    openContactezNousDialog() {
        this.dialog.open(ContactezNousDialogComponent);
    }

    openReprendrePlusTardDialog() {
        this.dialog.open(ReprendrePlusTardComponent);
    }

    ngAfterViewInit(): void {
        if ('1' === localStorage.getItem('showCallback')) {
            this.dialog.open(ContactezConseillerDialogComponent);
            localStorage.removeItem('showCallback');
        }
    }
}
