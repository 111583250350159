import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IntroEcranComponent } from './intro-ecran/intro-ecran.component';
import { TitreEcranComponent } from './titre-ecran/titre-ecran.component';
import { IconComponent } from './icon/icon.component';
import { PictoComponent } from './picto/picto.component';
import { BoutonComponent } from './bouton/bouton.component';
import { ZoneDevisComponent } from './zone-devis/zone-devis.component';
import { ZoneParrainageComponent } from './zone-parrainage/zone-parrainage.component';
import { BandeauOffreComponent } from './bandeau-offre/bandeau-offre.component';
import { LoaderComponent } from './loader/loader.component';
import { FileInputComponent } from './file-input/file-input.component';
import { BoutonLoaderComponent } from './bouton-loader/bouton-loader.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DomaineSoinOffreComponent } from './domaine-soin-offre/domaine-soin-offre.component';
import { ListeArgumentsComponent } from './liste-arguments/liste-arguments.component';
import { CarteOffreComponent } from './carte-offre/carte-offre.component';
import { FinalisationDevisComponent } from './finalisation-devis/finalisation-devis.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { BoiteParrainComponent } from './boite-parrain/boite-parrain.component';
import { BoiteOffreComponent } from './boite-offre/boite-offre.component';

@NgModule({
    declarations: [
        BandeauOffreComponent,
        BoutonComponent,
        BoutonLoaderComponent,
        DomaineSoinOffreComponent,
        FileInputComponent,
        IconComponent,
        IntroEcranComponent,
        LoaderComponent,
        PictoComponent,
        TitreEcranComponent,
        ZoneDevisComponent,
        ZoneParrainageComponent,
        ListeArgumentsComponent,
        CarteOffreComponent,
        FinalisationDevisComponent,
        BoiteParrainComponent,
        BoiteOffreComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        PipesModule,
        MatButtonToggleModule,
        MatLegacyCheckboxModule,
    ],
    exports: [
        BandeauOffreComponent,
        BoutonComponent,
        BoutonLoaderComponent,
        DomaineSoinOffreComponent,
        FileInputComponent,
        IconComponent,
        IntroEcranComponent,
        LoaderComponent,
        PictoComponent,
        TitreEcranComponent,
        ZoneDevisComponent,
        ZoneParrainageComponent,
        ListeArgumentsComponent,
        CarteOffreComponent,
        FinalisationDevisComponent,
        BoiteOffreComponent,
    ],
})
export class SharedModule {}
