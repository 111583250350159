import { AuthenticationService, ProfilBaseService, NomProduitEnum } from 'common-lib';
import { Enfant } from '../../models/enfant';
import { Profil } from '../../models/profil';
import { Injectable } from '@angular/core';
import { NiveauBesoin } from '../../models/niveau-besoin';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfilSoel } from '../../models/profil-soel';
import { QualificationDesProfils } from '../../models/qualification-des-profils';
import { ProfilRepository } from '../../repositories/profil.repository';
import { CollecteInfos } from '../../models/collecte-infos';

@Injectable()
export class ProfilService extends ProfilBaseService {
    protected _profil: BehaviorSubject<Profil> = new BehaviorSubject(<Profil>{});
    public readonly profil: Observable<Profil> = this._profil.asObservable();

    constructor(
        protected profilRepository: ProfilRepository,
        protected authenticationService: AuthenticationService,
    ) {
        super(profilRepository, authenticationService);
        this.restoreLocalProfil();
    }

    public updateNiveauBesoin(niveauBesoin: NiveauBesoin) {
        const profil = this._profil.getValue();
        const profilSoel = profil.profilSoel || <ProfilSoel>{};

        profilSoel.niveauBesoin = niveauBesoin;
        profil.profilSoel = profilSoel;

        super.updateProfil(profil);
    }

    public updateHas100Sante(has100Sante: boolean) {
        const profil = this._profil.getValue();

        if (profil.profilSoel) {
            profil.profilSoel.has100Sante = has100Sante;
        }
        super.updateProfil(profil);
    }

    protected defaultProfil(): Profil {
        return {
            profilSoel: {
                niveauBesoin: {
                    soinsCourants: 2,
                    hospitalisation: 2,
                    dentaire: 2,
                    optique: 2,
                },
                has100Sante: true,
            },
        };
    }

    public formatProfilFromApi(profil: Profil) {
        super.formatProfilFromApi(profil);

        if (profil.profilSoel?.conjoint && profil.profilSoel?.conjoint.dateNaissance) {
            if (profil.profilSoel?.conjoint) {
                profil.profilSoel.conjoint.dateNaissance = new Date(profil.profilSoel?.conjoint.dateNaissance);
            }
        }

        profil.profilSoel?.enfants?.forEach((enfant: Enfant) => {
            if (enfant.dateNaissance) {
                enfant.dateNaissance = new Date(enfant.dateNaissance);
            }
        });
    }

    public formatForApi(profil: Profil) {
        super.formatForApi(profil);

        if (profil.profilSoel?.conjoint && profil.profilSoel?.conjoint.dateNaissance) {
            profil.profilSoel?.conjoint.dateNaissance.setHours(12);
        } else if (profil.profilSoel?.conjoint) {
            profil.profilSoel.conjoint = undefined;
        }

        profil.profilSoel?.enfants?.forEach((enfant: Enfant) => {
            if (enfant.dateNaissance) {
                enfant.dateNaissance.setHours(12);
            }
        });
    }

    public qualificationDesProfils(
        profil: Profil,
        qualificationDesProfils: QualificationDesProfils,
    ): Observable<Profil> {
        return this.profilRepository
            .qualificationDesProfils(profil, qualificationDesProfils)
            .pipe(this.saveProfilFromSwitchMap);
    }

    collecteInfos(profil: Profil, collecteInfos: CollecteInfos): Observable<Profil> {
        if (!collecteInfos.parrain?.nom) {
            collecteInfos.parrain = undefined;
        }

        return this.profilRepository.collecteInfos(profil, collecteInfos).pipe(this.saveProfilFromSwitchMap);
    }

    public isTNS(profil: Profil) {
        return (
            NomProduitEnum.SMI_SANTE_PRO === profil.offre?.produit?.nomProduit ||
            NomProduitEnum.SMI_SANTE_SENIORS_PRO === profil.offre?.produit?.nomProduit
        );
    }
}
