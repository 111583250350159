import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
    name: 'errorPrioritizer',
})
export class ErrorPrioritizerPipe implements PipeTransform {
    transform(errors: ValidationErrors | null | undefined): string {
        if (!errors) {
            return '';
        }

        let chosenError = '';

        if (errors.hasOwnProperty('api')) {
            chosenError = errors['api'];
        } else {
            const errorsExceptRequired = Object.keys(errors).filter((key) => {
                return key != 'required';
            });

            if (errors.hasOwnProperty('matDatepickerMax')) {
                const maxDate = <Date>errors['matDatepickerMax']['max'];
                chosenError = `Choisissez une date antérieure ou égale au ${maxDate.toLocaleDateString()}`;
            } else if (errors.hasOwnProperty('matDatepickerMin')) {
                const minDate = <Date>errors['matDatepickerMin']['min'];
                chosenError = `Choisissez une date postérieure ou égale au ${minDate.toLocaleDateString()}`;
            } else if (errors.hasOwnProperty('numberAndIndicative')) {
                chosenError = "Ce numéro ne correspond pas à l'indicatif choisi";
            } else if (errorsExceptRequired.length > 0) {
                chosenError = 'Le format est invalide.';
            } else {
                chosenError = 'Ce champ est requis.';
            }
        }

        chosenError = Array.isArray(chosenError) ? chosenError[0] : chosenError;
        return chosenError;
    }
}
