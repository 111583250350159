import { Component, Input, OnInit } from '@angular/core';
import { TaillePicto } from '../../../enums';

@Component({
    selector: 'app-picto',
    templateUrl: './picto.component.html',
    styleUrls: ['./picto.component.scss'],
})
export class PictoComponent implements OnInit {
    @Input() nomPicto: string = '';
    @Input() taille: TaillePicto = TaillePicto.M;

    cheminPicto: string = '';

    ngOnInit(): void {
        this.cheminPicto = this.buildCheminPicto(this.nomPicto);
    }

    private buildCheminPicto(nomPicto: string): string {
        return `${nomPicto}.svg`;
    }
}
