import { Component, EventEmitter, Input, NgIterable, OnInit, Output } from '@angular/core';
import { FontSize, TaillePicto } from '../../../enums';

@Component({
    selector: 'app-carte-offre',
    templateUrl: './carte-offre.component.html',
    styleUrls: ['./carte-offre.component.scss'],
})
export class CarteOffreComponent {
    TaillePicto = TaillePicto;
    @Input() preconise?: boolean = false;
    @Input() titre: string = '';
    @Input() pretitre?: string;
    @Input() lien?: string;
    @Input() contenu?: string;
    @Input() etiquetteBouton?: string = 'Choisir cette offre';
    @Input() loading: boolean = false;
    @Input() boutonSecondaire?: boolean = false;
    @Output() clicBouton = new EventEmitter();

    onClicBouton() {
        this.clicBouton.emit();
    }

    protected readonly FontSize = FontSize;
}
