import { Component } from '@angular/core';
import { FontSize } from '../../../enums';

@Component({
    selector: 'app-zone-parrainage',
    templateUrl: './zone-parrainage.component.html',
    styleUrls: ['./zone-parrainage.component.scss'],
})
export class ZoneParrainageComponent {
    FontSize = FontSize;
}
