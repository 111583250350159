import { Component, Inject, InjectionToken, Input } from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input() public activeStep: string = '';

    constructor(@Inject(BREADCRUMBS_STEPS) public steps: string[]) {}
}

export const BREADCRUMBS_STEPS = new InjectionToken<string>('');
