<div class="d-flex justify-content-end">
  <mat-icon mat-dialog-close>clear</mat-icon>
</div>
<ng-container *ngIf="!success">
  <h2 mat-dialog-title>
    <app-icon class="pe-2" nomIcon="enregistrer" size="2"></app-icon>
    <span>Reprendre votre demande plus tard</span>
  </h2>
  <div mat-dialog-content>
    <p>
      Vous avez la possibilité d’interrompre votre parcours et de le reprendre plus tard. Pour cela, saisissez
      simplement
      votre adresse email. Vous recevrez un lien vous permettant d’accéder à l’étape à laquelle vous vous étiez arrêté.
    </p>
    <form [formGroup]="saveForm" (ngSubmit)="save()">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input matInput type="email" name="email" formControlName="email"/>
        <mat-error *ngIf="saveForm.get('email')?.invalid">
          {{ saveForm.get('email')?.errors | errorPrioritizer }}
        </mat-error>
      </mat-form-field>
      <app-bouton class="mb-4 mb-md-0 mt-md-2 bouton-primaire" [disabled]="saveForm.invalid || saving" [fontSize]="FontSize.XL"
        [submit]="true" [loading]="saving">
        Enregistrer ma demande
      </app-bouton>
    </form>
  </div>
  <a class="link-popup m-3" href="https://www.mutuelle-smi.com/protection-des-donnees/">
    Consulter notre politique de protection des données
  </a>

</ng-container>
<ng-container *ngIf="success">
  <h2 mat-dialog-title>
    <app-icon class="pe-2" nomIcon="enregistrer" size="2"></app-icon>
    <span>Votre demande a bien été enregistrée</span>
  </h2>
  <div mat-dialog-content>
    <p>
      Le mail avec votre lien d’accès à l’étape à laquelle vous vous êtes arrêté a été envoyé.
    </p>
  </div>
</ng-container>
