import { Injectable, InjectionToken } from '@angular/core';
import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { ConfigService } from '../lib/services';

@Injectable()
export class CookiebotConfig extends NgxCookiebotConfig {
    public cbId: string;
    public blockingMode: string;
    public culture: string;
    public loadScript: boolean;
    public cdn: string;

    constructor(configService: ConfigService) {
        super();
        this.cbId = configService.cookieCbid;
        this.blockingMode = 'manual';
        this.culture = 'FR';
        this.loadScript = true;
        this.cdn = 'eu';
    }
}
export const COOKIE_CONFIG = new InjectionToken('Cookieconfig');
