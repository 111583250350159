import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Ville } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ZipService {
    private configService: ConfigService;
    constructor(
        private http: HttpClient,
        configService: ConfigService,
    ) {
        this.configService = configService;
    }

    getData(options: string) {
        const zipUrl = `${this.configService.apiZip}${options}`;
        return this.http.get<Ville[]>(zipUrl).pipe(
            tap((data) => {
                return data;
            }),
        );
    }
}
